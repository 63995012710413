@import url("https://fonts.googleapis.com/css2?family=Karla&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;900&family=Kaisei+Opti:wght@500&family=Karla&display=swap");

* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

.dropdown-content::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(198, 227, 236);
  border-radius: 10px;
  background-color: #bde1efe5;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #064E73;
  border-radius: 0px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #F78F2F;
}

.nav-link.active {
  color: #f77e53 !important;
}

/* .nav-text.active {
  color: #f77e53 !important;
} */
.nav-text {
  position: relative;
}

.nav-text::before {
  content: "";
  position: absolute;
  width: 0%;
  height: 3px;
  border-radius: 10px;
  bottom: 0;
  left: 0;
  background-color: #f77e53;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.4s ease-in-out;
}

.nav-text:hover::before {
  width: 100%;
  transform: scaleX(1);
  transform-origin: bottom left;
}

.H1 {
  font-weight: 900;
}

.h3 {
  font-size: 30px;
  font-weight: 800;
}

.linktagcolor {
  color: #000 !important;
}

.linktagcolor :hover {
  color: #000;
}

/* loading new */

#progressbar {
  height: 26px;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 200px;
  background: #d04615 !important;
  border-radius: 30px;
  margin: -20px 0 0 -100px;
  padding: 2px;
}

#loading {
  transition: all 500ms ease;
  height: 20px;
  width: calc(100% - 10px);
  border-radius: 10px;
  background-color: whitesmoke;
  position: absolute;
  margin: 1px;
  padding: 3px;
  display: inline-block;
  animation: loador 5s ease infinite;
}

#load {
  font-family: "Poppins", sans-serif;
  font-size: 2rem;
  text-align: center;
  margin-top: 30px;
  letter-spacing: 1px;
}

@keyframes loador {
  0% {
    width: 2%;
  }

  10% {
    width: 10%;
  }

  50% {
    width: 60%;
  }

  100% {
    width: 100%;
  }
}

/* loading card css   */

.lodindingcontner {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #f7f4f4;
  min-height: 100vh;
  padding: 30px;
}

.loadingcards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.loadingcard {
  margin: 10px;
  width: 300px;
  background: rgb(224, 213, 213);
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
}

.loadingcard .image {
  img {
    max-width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
}

.loadingcard .content {
  padding: 20px 25px;
}

.loadingcard.is-loading .image,
.loadingcard.is-loading h2,
.loadingcard.is-loading p {
  background: rgb(223, 220, 220);
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
}

.loadingcard.is-loading .image {
  height: 180px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.loadingcard.is-loading h2 {
  height: 28px;
}

.loadingcard.is-loading p {
  height: 36px;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .loadingcard {
    width: 100%;
  }
}

.web-sion-customer-right img {
  width: 90px !important;
  height: 90px;
  border-radius: 50%;
  border: outset;
  object-fit: contain;
}

#food h2 {
  text-align: center;
  font-size: 2.5rem;
  font-weight: 400;
  margin-bottom: 40px;
  text-transform: uppercase;
  color: #000000;
}

.food-container img {
  display: block;
  width: 100%;
  margin: auto;
  object-fit: fill;
  object-position: center;
}

.img-container {
  height: 220px;
  width: 100%;
  position: relative;
  overflow: hidden;
  object-fit: contain;
  gap: 4px;
  transition: all 0.4s ease-in-out 0.3s;
}

.image_project {
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: all 0.2s ease-in-out 0.3s;
}

.img-content {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  z-index: 2;
  text-align: center;
  transition: all 0.3s ease-in-out 0.1s;
}

.img-content a {
  font-size: 10px;
}

.img-container::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.871);
  opacity: 0;
  z-index: 1;
  transform: scaleY(0);
  transform-origin: 100% 100%;
  transition: all 0.7s;
}

.img-container:hover::after {
  opacity: 0.7;
  transform: scaleY(1);
}

.img-container:hover .img-content {
  opacity: 1;
  top: 60%;
}

.owl-theme .owl-dots button span {
  display: none !important;
}

.owl-carousel-container {
  width: 100%;
  /* or specify a specific width */
  height: 300px;
  /* or specify a specific height */
}

html {
  padding: 0px !important;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

ul li {
  list-style: none;
}

.btn:focus {
  outline: 0;
  box-shadow: none;
}

/* whatapps fixed-icon */
.nav-icon-whatapp {
  position: fixed;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 9;
}

.nav-icon-whatapp a {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  transition: all 0.3s ease;
  color: #fff;
  margin-bottom: 8px;
  border-radius: 100%;
}

.nav-icon-whatapp a:first-child {
  background-color: #5774ff;
}

.nav-icon-whatapp a:nth-child(2) {
  background-color: #25d366;
}

.nav-icon-whatapp a:nth-child(3) {
  background-color: #f77e53;
}

.nav-icon-whatapp a:nth-child(4) {
  background-color: #3b5998;
}

.nav-icon-whatapp a:nth-child(5) {
  background-color: #000000;
}

.nav-icon-whatapp a:nth-child(6) {
  background-color: #0a66c2;
}

.nav-icon-whatapp a:nth-child(7) {
  background-color: #ff0000;
}

.nav-icon-whatapp a:last-child {
  background-color: #ff3ac0e5;
}

.nav-icon-whatapp a i {
  color: #fff;
  font-size: 15px;
}

/* ======end====== */
/* Top Nav */
.main-top-nav {
  background-color: #064e73;
  display: flex;
  justify-content: space-between;
  padding: 10px 100px;
  align-items: center;
  flex-wrap: wrap;
}

.right-top-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.right-Left-nav-section {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 18px;
}

.left-top-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 18px;
}

.left-left-nav-items {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: white;
  font-family: inter;
  font-size: 18px;
  font-weight: 500;
}

p.Right-nav-follow {
  font-size: 17px;
  font-family: inter;
  color: white;
}

a.left-nav-Number {
  font-size: 17px;
  font-weight: 500;
  font-family: inter;
  color: white;
  text-decoration: none;
}

/* Navigation */
.sion-navigation {
  width: 100%;
  height: 76px;
  margin: 10px 0px;
  border-radius: 0px 0px 58px 58px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-item.nav-text.dropdown .dropdown-content .dropbtn a {
  color: rgba(0, 0, 0, 0.878) !important;
}

.sion-navigation-sec1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sion-navigation-sec-list-tems {
  font-size: 18px;
  font-weight: 700;
  color: #006a90;
  font-family: "Inter";
}

.sion-navigation-sec3 {
  background: #064e73 !important;
  border-radius: 6px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 192px;
}

.sion-navigation-sec-list-contect {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

a.btn.sion-navigation-sec3-btn {
  color: white;
  font-size: 16px;
  font-family: "Inter";
  font-weight: 500;
}

.navigation-sdmi {
  gap: 20px;
}

button.btn.book-t {
  padding: 0px;
}

section.nav-sec {
  top: 0%;
  z-index: 999;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 0px 0px 58px 58px;
  transition: all 0.8s ease-in-out;
}

.sticky {
  position: fixed;
  transition: all 0.9s ease-in-out;
  top: -100%;
  width: 100%;
  z-index: 1000;
}

.sticky.true {
  top: 0;
}

/* hero Section */

.web-top-section {
  width: 302px;
  height: 27px;
  background: #064f93;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

p.save-section-content {
  font-size: 18px;
  font-weight: 400;
  font-family: "Karla", sans-serif;
}

a.save-images {
  position: relative;
  background: white;
  padding: 5px;
  border-radius: 50%;
  left: -12px;
}

p.main-web-content {
  font-size: 40px;
  font-weight: 800;
  font-family: inter;
  color: #141631;
  margin-top: 15px;
}

p.after-main-web-content {
  font-size: 16px;
  font-weight: 400;
  font-family: Kaisei Opti;
  padding-top: 5px;
}

button.btn.button-get {
  width: 192px;
  height: 49px;
  background: #064e73;
  border-radius: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
}

a.get-web-start {
  font-size: 24px;
  font-weight: 600;
  font-family: inter;
  text-decoration: none;
  color: #ffffff;
}

.web-top5-main-section1 {
  display: flex;
  align-items: center;
  background-color: #eaeaea;
  width: 211px;
  justify-content: center;
  border-radius: 7px;
  height: 48px;
  margin-top: 15px;
}

.clg-web-client2 {
  display: flex;
  align-items: center;
  position: relative;
  left: -20px;
  padding-top: 4px;
}

.clg-web-client1 {
  padding-top: 4px;
}

a.web-clg-fiftyclients {
  font-size: 18px;
  font-weight: 800;
  text-decoration: none;
  font-family: "Karla", sans-serif;
  position: relative;
  left: -5px;
  color: #000000;
}

.web-top5-main-section2 {
  width: 196px;
  height: 48px;
  background: #25313c;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 15px;
}

p.web-clg-how-work {
  font-size: 20px;
  font-weight: 700;
  font-family: "Karla";
  color: white;
}

.web-top5-section {
  gap: 10px;
}

.Hero-section {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 0px 100px;
}

.web-top4-section {
  margin-top: 15px;
}

.web-top5-section {
  gap: 10px;
  padding-left: 10px;
  margin-top: 10px;
}

.web-top5-section {
  display: flex;
  gap: 10px;
}

.col-md-6.Hero-section-part-1 {
  margin-top: 20px;
}

.Hero-section-part-1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}

/* Best Company Indore */
p.web-cls-best2 {
  text-align: center;
  color: #141631;
  font-size: 32px;
  font-weight: 800;
  font-family: inter;
  margin-top: 50px;
}

p.web-cls-best4 {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  font-family: inter;
  padding-top: 10px;
  color: #141631;
}

.web-best-com {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.web-best-section1 {
  width: 338px;
  height: 112px;
  background: #0c3f59;
  border-radius: 17px 0px 0px 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 15px;
  box-shadow: 0px 15px 10px -15px #111;
}

.web-best-section2 {
  width: 338px;
  height: 112px;
  background: #0c3f59;
  border-radius: 0px 0px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 15px;
  box-shadow: 0px 15px 10px -15px #111;
}

.web-best-section3 {
  width: 338px;
  height: 112px;
  background: #0c3f59;
  border-radius: 0px 17px 17px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 15px;
  box-shadow: 0px 15px 10px -15px #111;
}

.sad-b {
  box-shadow: 0px 0px 30px rgba(85, 85, 85, 0.1);
  padding: 15px;
  border-radius: 25px;
}

.web-best-section-left {
  width: 69px;
  height: 69px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

p.web-best-section-counting {
  font-size: 24px;
  font-weight: 900;
  color: wheat;
  font-family: inter;
  text-align: left;
}

p.web-best-section-details {
  font-size: 25px;
  font-weight: 400;
  font-family: inter;
  color: white;
}

.websion-best-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* About Company */
h2.web-about-heading {
  font-size: 25px;
  font-weight: 800;
  font-family: "Inter";
  /* margin-top: 100px; */
  border-bottom: 2px solid #f77e53;
  display: inline-block;
  padding-bottom: 8px;
}

p.web-about-paragraph {
  font-size: 16px;
  font-weight: 400;
  text-align: justify;
  font-family: "Inter";
}

.web-about-sec-top {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: self-start;
  padding: 15px 15px;
  background: white;
  position: absolute;
  left: 67%;
}

.web-about-left-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.web-about-sec-bottom {
  position: relative;
}

.col-md-6.web-about-right-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
}

p.web-about-top-para {
  font-size: 14px;
  font-weight: 400;
  font-family: inter;
  line-height: 25px;
  color: #006c73;
}

p.web-about-right-sec-para {
  font-size: 16px;
  font-weight: 400;
  font-family: "Inter";
  color: #141631;
  line-height: 30px;
}

.web-about-right-bottom-section {
  width: 142px;
  height: 39px;
  background: white;
  border: 1px solid #000000;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

a.web-about-right-sec-btn {
  font-size: 16px;
  font-weight: 600;
  font-family: inter;
  color: #31334a;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Services */
h2.sion-service-hadding {
  font-size: 25px;
  font-weight: 800;
  font-family: "Inter";
  color: #141631;
}

.sion-service-discription {
  font-size: 16px;
  font-weight: 400;
  font-family: inter;
  color: #141631;
}

.sion-services-web {
  width: 325px;
  height: 119px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 0px 25px;
  border-radius: 12px;
}

.sion-ser-images {
  width: 95px;
  height: 80px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
}

a.sion-service-name {
  font-size: 16px;
  font-weight: 700;
  font-family: "Inter";
  color: #2e3d58;
  text-decoration: none;
  text-align: center;
}

.sion-ser-name {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 200px;
}

.web-sion-service-bottom {
  /* background-image: url(../images/servicesbg.png); */
  background-image: url('../../public/assets/images/servicesbg.png');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
}

.sion-services-web-sections {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

button.Seervice-ex-btn {
  width: 142px;
  height: 39px;
  background: #064e73;
  border: 1px solid #064e73;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

a.Seervice-explore-btn.text-white {
  font-size: 16px;
  font-weight: 600;
  font-family: inter;
  text-decoration: none;
}

.service-more-btn {
  margin-left: 50rem;
  margin-bottom: 69px;
  margin-top: 50px;
}

/* After Bottom Section */
.af-btm-sec-images::before {
  position: absolute;
  content: "";
  background-image: url(../../public/assets/images/bottomser1.png);
  bottom: 30px;
  z-index: -1;
  width: 300px;
  height: 300px;
  background-repeat: no-repeat;
  top: -4%;
}

.af-btm-sec-images {
  position: relative;
}

.af-btm-sec-rights {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

p.af-btm-sec-hadding {
  font-size: 26px;
  font-weight: 700;
  color: #000;
  font-family: inter;
  padding-bottom: 10px;
}

p.af-btm-sec-agter-hadd {
  font-size: 26px;
  color: #0a0707;
  padding: 5px 0px;
  font-weight: 500;
}

.af-btm-sec-images img {
  width: 100%;
  height: 270px;
  object-fit: cover;
}

a.sion-pre {
  font-size: 15px;
  text-decoration: none;
  border: 1px solid #219653;
  background-color: #219653;
  padding: 4px 9px;
  border-radius: 6px;
  color: white;
  margin-left: 20px;
}

p.af-btm-sec-para {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  font-family: "Inter";
  padding-bottom: 15px;
  padding-top: 8px;
}

.af-btm-section-clients {
  display: flex;
  padding-top: 15px;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

p.af-btm-sec-clients10 {
  font-size: 32px;
  font-weight: 800;
  font-family: system-ui;
}

p.af-bottom-sec-client {
  font-size: 16px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
}

button.know-more-btn {
  border: 1px solid #f77e53;
  background-color: #f77e53;
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: 25px;
}

a.knoe-more-button {
  font-size: 16px;
  font-weight: 500;
  color: white;
  text-decoration: none;
  font-family: system-ui;
}

.for-margin {
  display: flex;
  align-items: center;
}

.ser-sec p {
  font-size: 14px;
  text-align: justify;
}

.ser-sec i {
  font-size: 28px;
  color: #f77e53;
}

.ser-sec h6 {
  font-weight: 700;
}

.af-btm-sec-right {
  text-align: center;
  width: 70%;
}

.why-c {
  display: flex;
  align-items: center;
  padding: 16px 0px;
}

.why-c i {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 3px 9px #cccccc;
  border-radius: 5px;
  font-size: 20px;
  color: #f77e53;
}

.why-c h6 {
  margin-left: 13px;
  text-transform: capitalize;
  font-size: 15px;
  margin-bottom: 0px;
}

/* Hear From Our Customer */

.checked {
  color: orange;
}

h2.web-customer-sec-hadding {
  text-align: center;
  font-size: 25px;
  font-family: "Inter";
  color: white;
  font-weight: 800;
}

.web-customer-section {
  background-image: url('../../public/assets/images/customerbg.png');
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px 0px;
}

p.web-customer-sec-para {
  text-align: center;
  padding-top: 10px;
  color: white;
  font-family: "Inter";
  font-weight: 400;
}

.web-sion-customer-sec {
  height: 238px;
  border: 1px solid white;
  background: white;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-inline: 8px;
}

.web-sion-customer-sec1 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
}

p.web-sion-customer-name {
  font-size: 14px;
  margin-left: 10px;
  font-family: inter;
  font-weight: 700;
  width: 8em;
}

p.web-sion-customer-real {
  font-size: 10px;
  margin-left: 10px;
  color: #ff5e4b;
  font-family: inter;
  font-weight: 400;
  padding-top: 10px;
  width: 12em;
}

.web-sion-customer-left {
  width: 190px;
  height: 170px;

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 6px 5px 40px rgb(0 0 0/12%);
  border-radius: 30% 10px;

  padding-left: 10px;
}

.web-sion-customer-right {
  position: relative;
  right: 17%;
}

p.web-sion-customer-comment {
  font-size: 13px;
  font-family: system-ui;
  text-align: left;
  color: #1e1e1e;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

p.web-sion-customer-rating {
  padding-top: 10px;
  font-size: 10px;
  margin-left: 10px;
}

.web-sion-customer-sec2 {
  right: -12px;
  padding-right: 12px;
  text-align: justify;
}

/* Our Recent Work */

.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
  width: 45%;
  border-radius: 25px;
  margin-top: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tab-bottom-section {
  padding: 0px 100px;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #ccc;
}

/* Blog section start*/
h2.sion-blog-sec-hadding {
  font-size: 25px;
  font-weight: 800;
  font-family: "Inter";
  text-align: center;
}

p.sion-blog-sec-pg {
  font-size: 16px;
  font-weight: 400;
  font-family: "Inter";
  text-align: center;
  padding-top: 3px;
}

.sion-blog-sec-top {
  padding-bottom: 36px;
}

section.sion-blog-sec {
  padding: 50px 0px;
}

.sion-blog-sec-bottom-inner1 {
  width: 266px;
  height: 143px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sion-blog-sec-bottom-inner2 {
  width: 93px;
  height: 21px;
  border: 1px solid #ff5e4b;
  background-color: #ff5e4b;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 10px;
  position: relative;
  bottom: 10px;
  margin-left: 20px;
}

a.blog-sec-bottom-inner2-date {
  font-size: 10px;
  font-family: "Inter";
  font-weight: 500;
  color: white;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.sion-blog-sec-bottom-inner3 {
  width: 266px;
  height: 146px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

h3.sion-blog-sec-bottom-inner31-hadd {
  font-size: 14px;
  font-weight: 600;
  font-family: "Inter";
  color: #fa9746;
  padding-top: 15px;
  padding-left: 10px;
}

p.sion-blog-sec-bottom-inner31-para {
  font-size: 12px;
  font-weight: 400;
  font-family: "Inter";
  color: #141631;
  padding-top: 15px;
  padding-left: 10px;
}

.sion-blog-sec-bottom-inner32 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 6px;
  padding-left: 10px;
  padding-right: 10px;
}

.sion-blog-sec-bottom-inner321 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

a.sion-blog-sec-bottom-inner31-name {
  font-size: 12px;
  font-weight: 400;
  font-family: "Inter";
  color: #ff5e4b;
  text-decoration: none;
}

a.sion-blog-sec-bottom-inner31-read {
  font-size: 12px;
  font-weight: 400;
  font-family: "Inter";
  color: #000000;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sion-blog-sec-bottom-inner322 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.sion-blog-sec-bottom-inner3 {
  width: 266px;
  height: 146px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  position: relative;
  bottom: 10px;
  border-radius: 3px;
}

.sion-blog-sec-bottom-inner32-line {
  width: 265.5px;
  color: black;
  border: 1px solid;
  margin-top: 15px;
}

.sion-blog-sec-bottom-inner {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sion-blog-sec-bottom {
  padding: 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

/* Footer Section Start */

/* Tabs */
h5.service-tabs-hadding {
  font-size: 25px;
  font-weight: 800;
  font-family: inter;
  text-align: center;
  color: #141631;
  margin-top: 50px;
}

p.service-tabs-paragraph {
  font-size: 16px;
  font-weight: 400;
  font-family: inter;
  text-align: center;
  color: #141631;
}

.tabs-sectioin-work {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.tabs-links {
  color: #4e4747;
  font-size: 16px;
  font-weight: 700;
  font-family: inter;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
  text-align: -webkit-center;
  margin: auto;
  display: flex;
  justify-content: center;
}

/* OWL */

/* Custom CSS for hoverable dropdown */
/* Style the dropdown container */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Style the button that triggers the dropdown */
.dropbtn {
  color: black;
  /* padding: 10px 20px; */
  border: none;
  cursor: pointer;
}

/* Style the dropdown content (hidden by default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 300px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 10px;
  height: auto;
  overflow-y: auto;
  font-weight: normal;
  filter: drop-shadow(0 0 0.75rem rgb(184, 176, 177));
}

/* Style the dropdown links */
.dropdown-content a {
  padding: 7px;
  text-decoration: none;
  display: block;
  font-size: medium;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.833);
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: white;
  color: #08304b;
  box-shadow: rgba(71, 61, 61, 0.24) 0px 3px 8px;
  font-weight: 600;
  font-family: "Inter";
}

/* Show the dropdown when hovering over the container */
.dropdown:hover .dropdown-content {
  display: block;
}

/* All pages linking */
.about-us-links {
  color: white;
  text-decoration: none;
}

.about-us-links:hover {
  text-decoration: none;
  color: #f77e53;
}

.event-item .thumb img {
  width: 100%;
  height: 220px;
  max-height: 100%;
  object-fit: cover;
}

.event-content {
  padding: 30px;
  background: #f0f7ff;
  position: relative;
  transition: all 0.5s ease 0s;
}

.event-content .date {
  position: absolute;
  background-color: #ff7350;
  width: 84px;
  height: 57px;
  left: 30px;
  padding: 4px;
  top: -33px;
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-family: "Jost", sans-serif;
}

.event-content .date strong {
  font-size: 24px;
  display: block;
  margin-top: 10px;
}

.event-content h3 {
  font-size: 24px;
  margin-bottom: 10px;
}

.event-content h3 a {
  color: #232323;
  font-size: 21px;
  font-weight: 700;
}

.time strong {
  color: #f77e53;
}

.event-content .time i {
  margin-left: 5px;
  margin-right: 5px;
}

.time i {
  color: whitesmoke;
}

.event-content p {
  color: #636161;
  padding: 6px 0px;
  font-size: 15px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.event-content h5 {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.time {
  font-size: 13px;
}

.time :hover {
  color: white;
}

.event-content h3 a:hover {
  text-decoration: none;
}

.event-item {
  overflow: hidden;
  transition: 0.3s;
}

.event-item:hover img {
  transition: 0.9s;
  transform: scale(1.2);
}

.event-content {
  transition: 0.5s;
}

.event-item:hover .event-content {
  background-color: #0c3f59;
  color: #fff;
}

.event-item:hover .event-content h3 a {
  color: #fff;
}

.event-item:hover .event-content p {
  color: #fff;
}

/* About section Start */
.about-hero-section-dark {
  background-image: url("../../public/assets/images/aboutus.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 58px 0px;
}

.about-us-hadding {
  font-size: 50px;
  font-weight: 800;
  color: white;
  font-family: "Inter";
}

p.about-priority-paragraph {
  font-size: 25px;
  font-family: inter;
  font-weight: 500;
  color: #302d2d;
  width: 1001px;
  height: 257px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.about-priority-para {
  display: flex;
  justify-content: center;
  align-items: center;
}

p.sion-about-section-para {
  font-size: 16px;
  font-weight: 500;
  font-family: system-ui;
}

.sdmi-about-image-sec {
  position: relative;
}

p.sdmi-about-us-para {
  font-size: 16px;
  font-weight: 500;
  font-family: system-ui;
}

.sdmi-about-image {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0px;
}

.sdmi-about-paragraph {
  display: flex;
  justify-content: center;
  align-items: center;
}

p.sion-our-vision {
  font-size: 25px;
  font-weight: 800;
  font-family: "Inter";
  color: #141631;
  margin-bottom: 0px;
}

p.sion-vision-content-para {
  font-size: 16px;
  font-weight: 500;
  font-family: system-ui;
  display: flex;
  align-items: center;
}

.vision-show-image {
  width: 60%;
  height: auto;
}

.sion-vision-show {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sion-vision-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.after-vision-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 100px auto;
}

.after-vision-hadding {
  font-size: 16px;
  font-weight: 700;
  font-family: "Inter";
  color: #f6861f;
}

.after-vision-brand-para {
  min-height: 165px;
  background: #ffffff;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.after-vision-sec {
  margin: 50px 0px 0px 0px;
  background: #f1f1f1;
}

.after-vision-brands {
  position: relative;
  top: 10%;
}

p.after-vision-brand-paragraph {
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  font-family: "Inter";
  color: #302d2d;
  padding: 0px 15px;
}

.lapi-show {
  width: 75%;
  height: 90%;
}

p.why-choose-sion-hadding {
  font-size: 25px;
  font-weight: 700;
  font-family: "Inter";
  color: #000000;
}

.why-choose-sion-paragraph {
  font-size: 16px;
  font-family: "Inter";
  font-weight: 500;
  color: #f87f54;
  padding-top: 25px;
}

.why-choose-sion {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.why-choose-sion-lapi {
  display: flex;
  justify-content: center;
  align-items: center;
}

.after-bottom-lapi {
  background-image: url("../../public/assets/images/bottomlapi.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 50px 0px;
  position: relative;
  /* bottom: 100px; */
  z-index: -1;
}

p.after-bottom-lapi-hadding {
  font-size: 16px;
  font-weight: 500;
  font-family: "Inter";
  color: #f8f8f8;
}

p.after-bottom-lapi-para {
  font-size: 16px;
  font-weight: 500;
  font-family: "Inter";
  color: #f8f8f8;
  padding-top: 20px;
}

/* Contact Section */
p.contact-bottom-sec-para {
  font-size: 16px;
  font-weight: 500;
  font-family: "Inter";
  color: #141631;
  text-align: center;
}

.contact-bottom-sec {
  background: #f1f1f1;
  padding: 100px 200px;
}

.office-information {
  background: linear-gradient(0deg,
      rgba(255, 255, 255, 1) 8%,
      rgba(241, 247, 254, 1) 10%,
      rgba(235, 243, 254, 1) 16%);
  border-radius: 30px;
  padding: 0px 50px 50px 50px;
  margin: 35px 0px 0px 0px;
}

.office-info-sec {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 35px;
}

.office-info-sec-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 254px;
  height: 114px;
  background: white;
  border-radius: 5px;
}

p.office-info-sec-hadding {
  font-size: 20px;
  font-weight: 500;
  font-family: "Inter";
  color: #0c3f59;
}

a.office-info-sec-datas {
  font-size: 12px;
  font-weight: 500;
  font-family: "Inter";
  color: #000000;
}

.office-info-sec-bottom1 {
  width: auto;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.office-info-sec-bottom2 {
  width: auto;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.office-info-sec-top {
  margin-bottom: 5px;
}

.contact-hero-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0px;
  background: white;
}

.form-icon-control {
  position: relative;
}

.fotm-icon {
  position: absolute;
  top: 5px;
  left: 12px;
  font-size: 20px;
}

.form-input-control {
  padding-left: 35px;
  background: #f9f9f9;
  border-radius: 10px;
  background: #f9f9f9;
  font-size: 20px;
  font-weight: 400;
  font-family: inter;
  color: #848484;
  height: 44px;
}

.contact-out-section {
  background: white;
  border-radius: 30px;
  padding: 5px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

p.contact-form-get {
  font-size: 25px;
  font-weight: 700;
  font-family: inter;
}

.form-group-space {
  margin-top: 30px;
}

.form-submit-btn {
  width: 192px;
  height: 36px;
  border-radius: 11px;
  background: #f77e53;
  color: white;
  font-size: 16px;
  font-weight: 600;
  font-family: "Inter";
  letter-spacing: 1px;
  margin-top: 50px;
}

/* service Section */

.about-us-links {
  color: white;
  text-decoration: none;
}

.about-us-links:hover {
  text-decoration: none;
  color: #f77e53;
}

/* Service-1 section */
.about-us-links {
  color: white;
  text-decoration: none;
}

.about-us-links:hover {
  text-decoration: none;
  color: #f77e53;
}

/* =================develoment-content-start-css======================= */
.img-top {
  /* margin: auto; */
  text-align: right;
  position: relative;
}

.img-top:before {
  position: absolute;
  content: "";
  width: 150px;
  height: 143px;
  background: url(../../public/assets/images/designimg.png);
  background-repeat: no-repeat;
  left: -11%;
  bottom: 9%;
  z-index: -1;
  background-position: center;
}

.img-top img {
  width: 100%;
  max-height: 400px;
  object-fit: cover;
  background-size: cover;
}

.img-top h2 {
  position: absolute;
  content: "";
  right: 0;
  bottom: -29px;
  text-align: right;
  display: inline-block;
  background: #ffffff;
  padding: 32px 78px;
  font-weight: 700;
}

.d-title1 {
  line-height: 20px;
  font-size: 14px;
  text-align: justify;
}

.d-content h3 {
  border-left: 4px solid #f77e53;
  padding-left: 8px;
}

.acc-content h3 {
  background-color: #f1f1f1;
  padding: 35px 0px;
  border-radius: 20px;
}

.accordian-sec .mb-0>a {
  display: block;
  position: relative;
  color: #000000;
  font-size: 17px;
}

.accordian-sec .mb-0>a:hover {
  text-decoration: none;
}

.accordian-sec .mb-0>a:after {
  content: "\2b";
  font-family: "Font Awesome 5 Free";
  position: absolute;
  right: 0;
  font-weight: 600;
}

.accordian-sec .mb-0>a[aria-expanded="true"]:after {
  content: "\f068";
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
}

.card-box {
  margin-bottom: 10px;
}

.accordian-sec .card-header {
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding: 18px 21px;
}

.border-left1 {
  border-right: 1px solid #f77e53;
}

.c-head h3 {
  font-size: 22px;
}

/* =================== */
:root {
  --main-color: #0caee9;
}

.serviceBox {
  color: #526a86;
  /* font-family: 'Montserrat', sans-serif; */
  text-align: center;
  padding: 10px 0 0;
  margin-bottom: 20px;
}

.serviceBox .service-content {
  background-color: #fff;
  height: 170px;
  width: 170px;
  padding: 32px 12px;
  margin: 0 auto 25px;
  border: 10px solid #fff;
  border-radius: 50%;
  box-shadow: -2px 2px 10px rgba(0, 0, 0, 0.2) inset,
    -2px 2px 12px rgba(0, 0, 0, 0.35), 0 0 0 10px var(--main-color);
}

.serviceBox .service-icon {
  font-size: 35px;
  line-height: 35px;
  margin: 0 auto 12px;
}

.serviceBox .title {
  /* font-family: 'Roboto Condensed', sans-serif; */
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0;
}

.serviceBox .description {
  font-size: 15px;
  line-height: 25px;
  text-align: justify;
  margin: 0 2px;
  color: #000;
}

.serviceBox.blue {
  --main-color: #0e6a9e;
}

.serviceBox.yellow {
  --main-color: #ffd206;
}

.serviceBox.red {
  --main-color: #fd4c22;
}

.serviceBox.ocean {
  --main-color: #17a2b8;
}

@media only screen and (max-width: 1199px) {
  .serviceBox {
    margin: 0 0 40px;
  }
}

.f-question {
  display: flex;
  justify-content: center;
}

.f-question h1 {
  text-align: center;
  border-bottom: 2px solid #f77e53;
  display: inline-block;
  padding-bottom: 13px;
  font-size: 30px;
}

.c-head p {
  text-align: justify;
}

.bottom-two h2 {
  background-color: #f1f1f1;
  padding: 35px 0px;
  border-radius: 20px;
}

/* ====end========= */

/* ==================service2 mobile app development services================== */

.e-commerce-d {
  text-align: justify;
}

.u-all-list ul li {
  font-size: 18px;
  line-height: 20px;
  padding: 10px 0 10px 52px;
  position: relative;
}

.u-all-list ul li:before {
  background-color: #fff;
}

.u-all-list ul li:before {
  background-color: #f2f2f2;
  background: url(https://conativeitsolutions.com/static/media/Right_Mark.1695cd08a27c4527823b.svg) no-repeat 50%;
  border-radius: 50%;
  content: "";
  height: 35px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 35px;
}

.app-h2 {
  padding-bottom: 22px;
}

.side-c1 {
  padding: 12px 25px;
  background: #f8f8f8;
}

.side-c1 h4 {
  font-weight: 700;
  text-align: center;
}

.e-commerce-service-h {
  font-weight: 700;
  border-left: 4px solid #f77e53;
  padding-left: 7px;
}

.img-app-t img {
  width: 100%;
  height: auto;
}

.app-h2 h2 {
  text-align: center;
  font-size: 27px;
}

:root {
  --main-color: #d64b3d;
}

.demo {
  background: radial-gradient(#fff, #ccc);
}

.main-timeline:after {
  content: "";
  display: block;
  clear: both;
}

.main-timeline .timeline {
  width: 50%;
  padding: 0 0 0 30px;
  margin: 0 5px 15px 0;
  float: left;
  position: relative;
  z-index: 1;
}

.main-timeline .timeline-content {
  background-color: #fff;
  padding: 35px 55px 35px 33px;
  box-shadow: 0 0 15px -3px rgba(0, 0, 0, 0.3);
  display: block;
  position: relative;
}

.main-timeline .timeline-content:hover {
  text-decoration: none;
}

.main-timeline .timeline-content:before {
  content: "";
  height: 50%;
  width: 50px;
  background-color: var(--main-color);
  transform: translateY(-50%);
  position: absolute;
  left: -30px;
  top: 50%;
  bottom: 15px;
  right: 15px;
  z-index: -1;
  clip-path: polygon(0 0, 30% 50%, 0 100%, 100% 100%, 100% 0);
}

.main-timeline .timeline-icon {
  color: #fff;
  background: var(--main-color);
  font-size: 35px;
  text-align: center;
  line-height: 80px;
  width: 80px;
  height: 80px;
  transform: translateY(-50%);
  position: absolute;
  top: 50%;
  right: -40px;
  z-index: 1;
}

.main-timeline .title {
  color: #333;
  font-size: 22px;
  font-weight: 600;
  margin: 0 0 5px;
}

.main-timeline .description {
  color: #050505;
  font-size: 14px;
  /* letter-spacing: 1px; */
  line-height: 20px;
  margin: 0;
}

.main-timeline .timeline:nth-child(even) {
  padding: 0 30px 0 0;
  margin: 0 0 15px 5px;
  float: right;
}

.main-timeline .timeline:nth-child(even) .timeline-content {
  padding: 35px 40px 35px 75px;
}

.main-timeline .timeline:nth-child(even) .timeline-content:before {
  transform: translateY(-50%) rotateY(180deg);
  left: auto;
  right: -30px;
}

.main-timeline .timeline:nth-child(even) .timeline-icon {
  left: -40px;
  right: auto;
}

.main-timeline .timeline:nth-child(4n + 2) {
  --main-color: #416f7f;
}

.main-timeline .timeline:nth-child(4n + 3) {
  --main-color: #373b3c;
}

.main-timeline .timeline:nth-child(4n + 4) {
  --main-color: #89348a;
}

@media screen and (max-width: 767px) {

  .main-timeline .timeline,
  .main-timeline .timeline:nth-child(even) {
    width: calc(100%);
    padding: 0 40px 0 30px;
  }

  .main-timeline .timeline:nth-child(even) {
    padding: 0 30px 0 40px;
  }
}

@media screen and (max-width: 479px) {

  .main-timeline .timeline,
  .main-timeline .timeline:nth-child(even) {
    width: 100%;
    padding: 40px 0 30px;
    margin: 0 0 30px;
  }

  .main-timeline .timeline-content,
  .main-timeline .timeline:nth-child(even) .timeline-content {
    text-align: center;
    padding: 70px 25px 35px;
  }

  .main-timeline .timeline-content:before,
  .main-timeline .timeline:nth-child(even) .timeline-content:before {
    transform: translateY(0) translateX(-50%) rotate(-90deg);
    left: 50%;
    right: auto;
    top: auto;
    bottom: -65px;
  }

  .main-timeline .timeline-icon,
  .main-timeline .timeline:nth-child(even) .timeline-icon {
    transform: translateY(0) translateX(50%);
    top: -40px;
    right: 50%;
    left: auto;
  }
}

.plans {
  text-align: center;
  padding-bottom: 25px;
}

/* ========== */
:root {
  --main-color: #f77e53;
}

.pricingTable {
  background: #fff;
  font-family: "Open Sans", sans-serif;
  text-align: center;
  border-radius: 35px;
  padding: 25px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
}

.pricingTable:before {
  content: "";
  border: 2px solid var(--main-color);
  border-bottom: none;
  border-radius: 35px 35px 0 0;
  position: absolute;
  left: 25px;
  right: 25px;
  top: 60px;
  bottom: 50px;
  z-index: -1;
}

.pricingTable .pricingTable-header {
  margin: 0 0 35px;
}

.pricingTable .pricing-icon {
  color: #fff;
  background-color: var(--main-color);
  font-size: 25px;
  line-height: 70px;
  height: 70px;
  width: 70px;
  margin: 0 auto 25px;
  border-radius: 50%;
}

.pricingTable .title {
  color: var(--main-color);
  font-size: 27px;
  font-weight: 700;
  text-transform: capitalize;
  margin: 0;
}

.pricingTable .pricing-content {
  padding: 0;
  margin: 0 0 35px;
  list-style: none;
  display: inline-block;
}

.pricingTable .pricing-content li {
  color: #010101;
  font-size: 16px;
  font-weight: 500;
  line-height: 35px;
  letter-spacing: 0.5px;
  margin: 0 0 7px;
}

.pricingTable .pricing-content li:last-child {
  margin: 0;
}

.pricingTable .price-value {
  color: #222;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.5px;
  margin: 0 0 25px;
}

.pricingTable .price-value span {
  color: #777;
  font-size: 25px;
  font-weight: 600;
}

.pricingTable .pricingTable-signup a {
  color: #fff;
  background-color: var(--main-color);
  font-size: 20px;
  font-weight: 700;
  text-transform: capitalize;
  padding: 7px 30px 8px;
  border-radius: 50px;
  display: inline-block;
  border: 4px solid #fff;
  box-shadow: 0 0 0 1px var(--main-color);
  transition: all 0.5s ease 0s;
}

.pricingTable .pricingTable-signup a:hover {
  color: var(--main-color);
  background-color: #fff;
  border-color: var(--main-color);
  box-shadow: none;
}

.pricingTable.orange {
  --main-color: #fe5835;
}

.pricingTable.green {
  --main-color: #009850;
}

@media only screen and (max-width: 990px) {
  .pricingTable {
    margin: 0 0 40px;
  }
}

.light-c {
  padding: 25px;
  background-color: #f5f5f5;
  border-left: 2px solid #000;
  font-family: cursive;
}

.app-list1 ul li {
  padding: 10px 0 10px 35px;
}

.app-list1 {
  background-color: whitesmoke;
  padding: 10px;
  border-radius: 5px;
  min-height: 317px;
}

p.l-bottom1 {
  font-weight: 600;
}

p.l-bottom1 i {
  color: #f77e53;
  font-size: 19px;
}

.bottom-sp {
  padding: 25px;
  background-color: #f5f5f5;
  border-left: 2px solid #000;
  font-family: cursive;
}

/* ==================service2 mobile app development services end================== */

/* Service-3 section */
.digitalh-w {
  width: 100%;
  max-height: 431px;
}

.e-commerce-services-show {
  display: flex;
  justify-content: center;
  align-items: center;
}

p.sion-dm {
  font-size: 16px;

  text-align: justify;
}

.lc-checks {
  list-style: none;
  margin: 0;
  padding: 63px 0;
  position: relative;
}

.lc-checks:after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 1;
  height: 100%;
  width: 1px;
  background: #ccc;
}

.lc-checks li {
  padding: 15px 0;
  height: 65px;
  line-height: 35px;
  position: relative;
  cursor: default;
}

.lc-checks__feature {
  position: absolute;
  background-color: #0596c914;
  padding: 0 10px;
  border-radius: 2px;
  transition: all 0.2s ease;
}

.lc-checks li:nth-child(odd) .lc-checks__feature {
  left: 50%;
  margin-left: 30px;
}

.lc-checks li:nth-child(even) .lc-checks__feature {
  right: 50%;
  margin-right: 30px;
}

.lc-checks__check {
  position: absolute;
  z-index: 2;
  left: 50%;
  margin-left: -15px;
  text-align: center;
  width: 30px;
  background: #fff;
  color: #0c3f59;
  border: 1px dashed #ccc;
  font-size: 1.2em;
  border-radius: 2px;
  transition: all 0.2s ease;
}

.lc-checks li:hover .lc-checks__check {
  transform: scale(1.2);
}

.lc-checks li:nth-child(odd):hover .lc-checks__feature {
  margin-left: 25px;
}

.lc-checks li:nth-child(even):hover .lc-checks__feature {
  margin-right: 25px;
}

.dm-service-list {
  list-style: none;
  counter-reset: list;
  padding: 0 1rem;
}

.dm-service-list-items {
  --stop: calc(100% / var(--length) * var(--i));
  --l: 62%;
  --l2: 88%;
  --h: calc((var(--i) - 1) * (180 / var(--length)));
  --c1: hsl(var(--h), 71%, var(--l));
  --c2: hsl(var(--h), 71%, var(--l2));

  position: relative;
  counter-increment: list;
  max-width: 45rem;
  margin: 2rem auto;
  padding: 2rem 1rem 1rem;

  border-radius: 0.25rem;
  overflow: hidden;
  background-color: white;
}

.dm-service-list-items::before {
  content: "";
  display: block;
  width: 100%;
  height: 10px;
  position: absolute;
  top: 0;
  left: 0;
  background: rgb(2, 0, 36);
  background: linear-gradient(90deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(3, 149, 201, 1) 0%,
      rgba(228, 216, 216, 1) 100%);
  border-radius: 5px;
}

h3.dm-service-list-items-hadding {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
}

.dm-service-list-items-hadding::before {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  margin-right: 1rem;
  width: 0.85rem;
  height: 0.85rem;
  content: "";
  padding: 0.25rem;
  border-radius: 50%;
  background: rgb(2, 0, 36);
  background: linear-gradient(90deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(3, 149, 201, 1) 0%,
      rgba(228, 216, 216, 1) 100%);
  color: white;
}

.sion-dm-service-content {
  font-size: 15px;
}

.sion-dm-ser-hadding {
  margin-bottom: 0px;
  font-size: 25px;
}

p.e-commerce-service2-detail {
  font-size: 16px;
  /* font-weight: 400; */
  font-family: sans-serif !important;
  /* color: #141631; */
}

.f-question {
  display: flex;
  justify-content: center;
}

.f-question h1 {
  text-align: center;
  border-bottom: 2px solid #f77e53;
  display: inline-block;
  padding-bottom: 13px;
  font-size: 31px;
}

.accordian-sec .mb-0>a {
  display: block;
  position: relative;
  color: #000000;
  font-size: 17px;
}

.accordian-sec .mb-0>a:hover {
  text-decoration: none;
}

.accordian-sec .mb-0>a:after {
  content: "\2b";
  font-family: "Font Awesome 5 Free";
  position: absolute;
  right: 0;
  font-weight: 600;
}

.accordian-sec .mb-0>a[aria-expanded="true"]:after {
  content: "\f068";
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
}

.card-box {
  margin-bottom: 10px;
}

.accordian-sec .card-header {
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding: 18px 21px;
}

.border-left1 {
  border-right: 1px solid #f77e53;
}

/* Internship */

.f-question {
  display: flex;
  justify-content: center;
}

.f-question h1 {
  text-align: center;
  border-bottom: 2px solid #f77e53;
  display: inline-block;
  padding-bottom: 13px;
  font-size: 31px;
}

.accordian-sec .mb-0>a {
  display: flex;
  position: relative;
  color: #000000;
  font-size: 16px;
}

.accordian-sec .mb-0>a:hover {
  text-decoration: none;
}

.accordian-sec .mb-0>a:after {
  content: "\2b";
  font-family: "Font Awesome 5 Free";
  position: absolute;
  right: 0;
  font-weight: 600;
}

.accordian-sec .mb-0>a[aria-expanded="true"]:after {
  content: "\f068";
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
}

.card-box {
  margin-bottom: 10px;
}

.accordian-sec .card-header {
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding: 18px 21px;
}

.border-left1 {
  border-right: 1px solid #f77e53;
}

.inter-show {
  width: 450px;
  height: 413px;
}

.intern-cer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inter-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border: 1px solid; */
  border-radius: 10px;
  margin-top: 2rem;
  max-width: 300px;
  color: #212121;
  background: whitesmoke;
  /* background: radial-gradient(circle, rgba(10,35,166,1) 0%, rgba(6,27,43,1) 100%); */
}

.sion-interns-provide {
  justify-content: space-around;
}

.inter-card1 {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

p.inter-card1-content {
  font-size: 14px;
  text-align: justify;
}

.inter-card2 {
  width: 100%;
  height: 190px;
}

p.inter-card-hadding {
  color: #f77e53;
}

.intern-top-head {
  font-size: 21px;
  font-weight: 700;
}

.intern-top-para {
  font-size: 15px;
}

.intern-top-paragraph {
  font-size: 15px;
}

.intern-top-headding {
  font-size: 21px;
  font-weight: 700;
  text-align: center;
}

.cardss {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  justify-content: center;
  margin-top: 40px;
}

.cards_item {
  display: flex;
  padding: 1rem;
}

.card_image {
  position: relative;
  max-height: 160px;
}

.card_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.note {
  position: absolute;
  top: 8px;
  left: 8px;
  padding: 4px 8px;
  border-radius: 0.25rem;
  background-color: #f77e53;
  font-size: 14px;
  font-weight: 700;
}

@media (min-width: 40rem) {
  .cards_item {
    width: 50%;
  }
}

@media (min-width: 56rem) {
  .cards_item {
    width: 33.3333%;
  }
}

.card {
  background-color: white;
  border-radius: 0.25rem;
  /* box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25); */
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.card_content {
  position: relative;
  padding: 16px 12px 32px 24px;
  margin: 16px 8px 8px 0;
  max-height: 290px;
  overflow-y: scroll;
}

.card_content::-webkit-scrollbar {
  width: 8px;
}

.card_content::-webkit-scrollbar-track {
  box-shadow: 0;
  border-radius: 0;
}

.card_content::-webkit-scrollbar-thumb {
  background: #f77e53;
  border-radius: 15px;
}

.card_title {
  position: relative;
  margin: 0 0 24px;
  padding-bottom: 10px;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
}

.card_title::after {
  position: absolute;
  display: block;
  width: 50px;
  height: 2px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f77e53;
  content: "";
}

hr {
  margin: 24px auto;
  width: 50px;
  border-top: 2px solid #f77e53;
}

.card_text p {
  margin: 0 0 24px;
  font-size: 14px;
  line-height: 1.5;
}

.card_text p:last-child {
  margin: 0;
}

li.card-list-con {
  list-style: unset;
  font-size: 14px;
}

/* Service-4 Section */
.graphic-services-sec {
  /* box-shadow: rgb(0 0 0 / 10%) 0px 5px 10px; */
  border-radius: 10px;
  background: #bcc0c12e;
  max-width: 260px !important;
  min-height: 179px;
  margin-top: 2rem;
}

h4.graphic-head-name {
  width: 100%;
  /* height: 50px; */
  display: flex;
  padding-top: 17px;
  justify-content: center;
  align-items: center;
  color: #f77e53;
  text-align: center;
  margin-bottom: 0px;
  font-size: 17px;
  font-weight: bold;
}

.gra-c h2 {
  font-size: 30px;
}

p.graphic-paragraph {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0px;
  color: #1a1919;
  font-size: 15px;
  padding-top: 12px;
}

.sion-graph-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.olcards {
  list-style: none;
  counter-reset: cardCount 0;
  font-family: sans-serif;
  display: flex;
  flex-direction: row;
  --cardsGap: 1rem;
  gap: var(--cardsGap);
  padding-bottom: var(--cardsGap);
  padding-bottom: 30px;
}

.olcards li {
  counter-increment: cardCount;
  display: flex;
  color: white;
  --labelOffset: 1rem;
  --arrowClipSize: 1.5rem;
  margin-top: var(--labelOffset);
}

.olcards li::before {
  content: counter(cardCount, decimal-leading-zero);
  background: white;
  color: var(--cardColor);
  font-size: 2em;
  font-weight: 700;
  transform: translateY(calc(-1 * var(--labelOffset)));
  margin-right: calc(-1 * var(--labelOffset));
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: 0.5em;
}

.olcards li .content {
  background-color: var(--cardColor);
  --inlinePadding: 1em;
  --boxPadding: 0.5em;
  display: grid;
  padding: var(--boxPadding) calc(var(--inlinePadding) + var(--arrowClipSize)) var(--boxPadding) calc(var(--inlinePadding) + var(--labelOffset));
  grid-template-areas:
    "icon title"
    "icon text";
  gap: 0.25em 1em;
  clip-path: polygon(0 0,
      calc(100% - var(--arrowClipSize)) 0,
      100% 50%,
      calc(100% - var(--arrowClipSize)) 100%,
      calc(100% - var(--arrowClipSize)) calc(100% + var(--cardsGap)),
      0 calc(100% + var(--cardsGap)));
  position: relative;
  width: 100%;
}

.olcards li .content::before {
  content: "";
  position: absolute;
  width: var(--labelOffset);
  height: var(--labelOffset);
  background: var(--cardColor);
  left: 0;
  bottom: 0;
  clip-path: polygon(0 0, 100% 0, 0 100%);
  filter: brightness(0.75);
}

.olcards li .content::after {
  content: "";
  position: absolute;
  height: var(--cardsGap);
  width: var(--cardsGap);
  background: linear-gradient(to right, rgb(0 0 0 / 9%), transparent 50%);
  left: 0;
  top: 100%;
}

.gra-c .g-dis {
  background-color: #f7f5f5;
  min-height: 140px;
  margin-bottom: 6px;
  padding: 0px 20px;
}

.olcards li .icon {
  grid-area: icon;
  align-self: center;
  font-size: 2em;
}

.olcards li .content .title {
  grid-area: title;
  font-size: 1.25em;
  /* font-weight: 700; */
}

.olcards li .content .text {
  grid-area: text;
}

.f-question {
  display: flex;
  justify-content: center;
}

.f-question h1 {
  text-align: center;
  border-bottom: 2px solid #f77e53;
  display: inline-block;
  padding-bottom: 13px;
  font-size: 30px;
}

.accordian-sec .mb-0>a {
  display: block;
  position: relative;
  color: #000000;
  font-size: 17px;
}

.accordian-sec .mb-0>a:hover {
  text-decoration: none;
}

.accordian-sec .mb-0>a:after {
  content: "\2b";
  font-family: "Font Awesome 5 Free";
  position: absolute;
  right: 0;
  font-weight: 600;
}

.accordian-sec .mb-0>a[aria-expanded="true"]:after {
  content: "\f068";
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
}

.card-box {
  margin-bottom: 10px;
}

.accordian-sec .card-header {
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding: 18px 21px;
}

.border-left1 {
  border-right: 1px solid #f77e53;
}

/* common css */

p.e-commerce-services-hadding {
  font-size: 40px;
  font-weight: 900;
  font-family: "Inter";
}

p.e-commerce-service2-detail {
  font-size: 16px;
  font-weight: 400;
  font-family: Kaisei Opti;
  color: #141631;
}

button.e-commerce-service3-btn {
  width: 192px;
  height: 49px;
  border-radius: 11px;
  background: #f77e53;
  border: 1px solid #f77e53;
}

a.e-commerce-service3-liink {
  font-size: 22px;
  font-weight: 800;
  font-family: "Inter";
  color: white;
  text-decoration: none;
}

.e-commerce-services {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  background: white;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.e-commerce-services-show {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

p.support-ecommerce-hadding {
  font-size: 25px;
  font-weight: 800;
  font-family: "Inter";
  color: #141631;
  text-align: center;
  padding: 20px 0px;
}

p.support-ecommerce-paragraph {
  font-size: 16px;
  font-weight: 400;
  font-family: "Inter";
  color: #141631;
  text-align: center;
}

.support-ecommerce-sec {
  margin: 2rem 0px;
}

.main-support-sf-container {
  height: 300px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 15px;
  max-width: 349px !important;
  margin-top: 30px;
  /* padding: 0px; */
  padding-left: 15px;
  padding-right: 15px;
  margin-left: 15px;
  margin-right: 15px;
}

.support-container1 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0px;
}

p.support-hadding {
  font-size: 24px;
  font-weight: 600;
  font-family: "Inter";
  color: #000000;
  text-align: center;
  padding: 0px 0px 10px 0px;
}

p.support-para {
  font-size: 14px;
  font-weight: 500;
  font-family: "Inter";
  color: #141631;
  text-align: center;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.for-top-line {
  height: 2px;
  background: #f77e53;
  margin: 20px 0px;
}

.support-container4 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

a.support-get-quote {
  font-size: 14px;
  font-family: "Inter";
  font-weight: 500;
  color: #fa8157;
  text-decoration: none;
}

.support-container4-1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.support-container4-2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sion-recent-workbg {
  height: 405px;
  background: #ebf3fe;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0px 100px;
}

.sion-package-blog {
  background-image: url("../../public/assets/images/Packagebg.png");
  background-repeat: no-repeat;
  background-size: contain;
}

p.sion-package-hadding {
  font-size: 25px;
  font-weight: 800;
  font-family: "Inter";
  color: white;
  text-align: center;
}

.sion-package-para {
  font-size: 16px;
  font-weight: 500;
  font-family: "Inter";
  color: white;
  text-align: center;
}

p.sion-package-bottom-card1-name {
  font-size: 20px;
  font-weight: 600;
  font-family: "Inter";
  color: #ff5e4b;
  text-align: center;
}

p.sion-package-bottom-card2-price {
  font-size: 25px;
  font-weight: 600;
  font-family: "Inter";
  color: #000000;
  text-align: center;
}

button.sion-package-bottom-card3-btn {
  width: 82px;
  height: 19px;
  border-radius: 3px;
  background: #f77e53;
  border: 1px solid #f77e53;
  display: flex;
  justify-content: center;
  align-items: center;
}

a.sion-package-bottom-card3-buy {
  font-size: 14px;
  font-family: inter;
  font-weight: 400;
  color: #ffffff;
  text-decoration: none;
}

p.package-bottom-card-blog2-detail {
  font-size: 13px;
  font-weight: 500;
  font-family: inter;
  color: #1e1e1e;
}

.sion-package-bottom-card {
  max-width: 277px !important;
  height: 330px;
  border-radius: 20px;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.sion-package-bottom-sec {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.sion-package-blog-section1 {
  margin: 65px 0px;
}

/* owl Carousel */
.web-customer-sec-bottom.owl-nav button {
  position: absolute;
  top: 50%;
  background-color: #000;
  color: #fff;
  margin: 0;
  transition: all 0.3s ease-in-out;
}

.web-customer-sec-bottom.owl-nav button.owl-prev {
  left: 0;
}

.web-customer-sec-bottom.owl-nav button.owl-next {
  right: 0;
}

.web-customer-sec-bottom.owl-dots {
  text-align: center;
  padding-top: 15px;
}

.web-customer-sec-bottom.owl-dots button.owl-dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  background: #ccc;
  margin: 0 3px;
}

.web-customer-sec-bottom.owl-dots button.owl-dot.active {
  background-color: #000;
}

.web-customer-sec-bottom.owl-dots button.owl-dot:focus {
  outline: none;
}

.web-customer-sec-bottom.owl-nav button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.38) !important;
}

.web-customer-sec-bottom.span {
  font-size: 70px;
  position: relative;
  top: -5px;
}

.web-customer-sec-bottom.owl-nav button:focus {
  outline: none;
}

/* ==============card-content-sec-start========== */
:root {
  --main-color: #ff421e;
}

.logo-design-sec .serviceBox {
  color: var(--main-color);
  font-family: "Poppins", sans-serif;
  text-align: center;
  padding: 35px 28px 25px;
  position: relative;
  z-index: 1;
  min-height: 342px;
}

.logo-design-sec .serviceBox:before,
.logo-design-sec .serviceBox:after {
  content: "";
  border-radius: 25px;
  border: 10px solid var(--main-color);
  clip-path: polygon(65% 0, 100% 0, 100% 35%, 35% 100%, 0 100%, 0 65%);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.logo-design-sec .serviceBox:after {
  border-width: 3px;
  clip-path: polygon(0 0,
      60% 0,
      55% 5%,
      96% 44%,
      100% 40%,
      100% 100%,
      40% 100%,
      43% 95%,
      5% 55%,
      0 60%);
  top: 3px;
  bottom: 3px;
  right: 3px;
  left: 3px;
}

.logo-design-sec .serviceBox .service-icon {
  font-size: 40px;
  margin: 0 0 10px;
}

.logo-design-sec .serviceBox .title {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: capitalize;
  margin: 0 0 10px;
}

.logo-design-sec .serviceBox .description {
  color: #555;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 22px;
  margin: 0s;
}

.logo-design-sec .serviceBox.grey {
  --main-color: #f77e53;
}

@media only screen and (max-width: 1199px) {
  .serviceBox {
    margin: 0 0 40px;
  }
}

.acc-img {
  padding-top: 20px;
}

.acc-img img {
  width: 100%;
  max-height: 350px;
  object-fit: cover;
}

.b-contop h6 {
  font-weight: 700;
}

.b-contop p {
  text-align: justify;
}

.b-contop {
  background-color: #dcdcdc5e;
  padding: 15px;
}

/* ============================================== */

/* =====animation-card====== */
:root {
  --color1: #eda978;
  --color2: #853300;
}

.demo {
  background-color: #eee;
}

.gra-card .serviceBox {
  color: var(--color2);
  background: linear-gradient(to right bottom, #fff, #f2f2f2);
  font-family: "Roboto", sans-serif;
  text-align: center;
  padding: 50px 0 0;
  border-radius: 20px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2), 0 0 0 5px #fff inset;
  position: relative;
  min-height: 380px;
}

.gra-card .serviceBox:before {
  content: "";
  background: var(--color1);
  width: calc(100% - 55px);
  height: 6px;
  transform: translateX(-50%);
  position: absolute;
  top: 0;
  left: 50%;
}

.gra-card .serviceBox .title {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0 25px 10px;
}

.gra-card .serviceBox .description {
  color: #888;
  font-size: 15px;
  line-height: 25px;
  margin: 0 25px 25px;
  text-align: justify;
}

.gra-card .serviceBox .service-icon {
  background: var(--color1);
  font-size: 35px;
  line-height: 70px;
  width: 70px;
  height: 70px;
  border-radius: 10px 30px 10px 10px;
  margin: 0 auto;
  position: absolute;
  bottom: 26px;
  left: 0;
  right: 0;
}

.gra-card .serviceBox.yellow {
  --color1: #edca79;
  --color2: #815800;
}

.gra-card .serviceBox.purple {
  --color1: #958dbe;
  --color2: #1a1f57;
}

.gra-card .serviceBox.blue {
  --color1: #7fccce;
  --color2: #035d5d;
}

@media only screen and (max-width: 1199px) {
  .gra-card .serviceBox {
    margin: 0 0 30px;
  }

  .ReactModal__Content {
    margin-right: -35%;
  }
}

/* ============terms& condition================ */
.privacy-head1 .highlight {
  padding: 15px;
  background-color: #f3f3f3;
  border-left: 2px solid #21a1cf;
  margin-top: 13px;
  font-family: cursive;
}

.term-h-top {
  border-left: 2px solid #21a1cf;
  padding-left: 5px;
}

.t-con-sec li {
  margin: 14px 0px;
}

/* job  */
.hr-border {
  margin: 24px auto;
  width: 50px;
  border-top: 2px solid #f77e53;
}

/*popup modal inquiry-form */

.lassun {
  color: #000 !important;
}

.ReactModal__Content .btn-outline-success {
  color: #fff;
  border-color: #0c3f59;
  width: 100%;
  background: #0c3f59;
  padding: 9px 0px;
}

.ReactModal__Content {
  position: absolute;
  inset: 50% auto auto 50%;
  border: 1px solid rgb(204, 204, 204);
  background: #f7feff !important;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  margin-right: -40%;
  transform: translate(-50%, -50%);
  z-index: 1100;
  max-width: 800px !important;
}

.ReactModal__Content {
  background-color: #f7feff !important;
}

.ReactModal__Overlay {
  position: fixed;
  inset: 0px;
  background-color: rgb(0 0 0 / 58%);
  z-index: 1000;
}

.modalformheading {
  font-size: 25px;
  font-weight: 600;
  border-bottom: 1px solid #0c3f59;
  display: inline-block;
  margin-bottom: 15px;
}

.close-button {
  padding: 1px 12px;
  border-radius: 0px;
  font-size: 20px;
}

.img-fix {
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-fix img {
  max-width: 100%;
  width: 400px !important;
  height: auto !important;
}

/* Our Team */
img.teamimg {
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  height: 152px;
  width: 152px;
  aspect-ratio: 3 / 1;
  mix-blend-mode: color-burn;
  object-fit: cover;
}

/* new footer */
.new_sion_footer {
  background-color: #0c3f59;
  position: relative;
  padding: 20px 0;

  & .nsf_top {
    background-color: #006a90;
    border-radius: 5px;
    position: relative !important;
    top: -50px;
    left: 11px;
    width: 100%;

    & svg {
      color: #f7bd52cc;

      &:hover:nth-child(1) {
        color: #F58621;
        transform: scale(1.3);
      }
    }

    & .new_contact_sec {
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 30px 0;

      & span {
        color: #fff;
      }

      & .nsf_social_media {
        display: flex;
        gap: 10px;

        & a {
          &:hover:nth-child(1) i {
            color: #316FF6;
            transform: scale(1.3);
          }

          &:hover:nth-child(2) i {
            color: #ee2a7b;
            transform: scale(1.3);
          }

          &:hover:nth-child(3) i {
            color: #1DA1F2;
            transform: scale(1.3);
          }

          &:hover:nth-child(4) i {
            color: #000000;
            transform: scale(1.3);
          }

          &:hover:nth-child(5) i {
            color: #CD201F;
            transform: scale(1.3);
          }

          &:hover:nth-child(6) i {
            color: #0A66C2;
            transform: scale(1.3);
          }

          & i {
            font-size: 23px;
            color: #f7bd52cc;
            transition: transform 0.5s ease;

          }
        }
      }

    }
  }

  & .nsf_middle {
    border: 3px solid #006a90;
    padding: 15px;

    & .logo_img {
      display: flex;
      justify-content: center;

      & img {
        max-width: 120px;
      }
    }

    & .nsf_head {
      color: #f7bd52cc;
      text-align: start;
      margin: 15px 0;
      position: relative;
      padding-bottom: 8px;
      padding-left: 38px;
      font-size: 24px;

      &::after {
        content: "";
        position: relative;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 70%;
        height: 100%;
        border-bottom: 5px double #006a90;

      }

    }

    & .address {
      color: #fff;
      text-align: justify;
      font-size: 12px;
      padding-left: 18px;

      & svg {
        color: #f7bd52cc;
        font-size: 20px;
        margin-right: 5px;

      }

    }

    & .footer_about {
      color: #fff;
      font-size: 12px;
      padding-left: 18px;
      margin: 10px 0;
      text-align: justify;

      &:hover {
        color: orange;
      }
    }

    & .nfs_items {
      padding-left: 20px;

      & a {
        text-align: start;
        color: #fff;
        font-size: 12px;
        margin-bottom: 4px;
        margin-left: 15px;
        text-decoration: none;
        transition: padding-left 0.3s ease-in-out;
        display: flex;
        align-items: baseline;
        gap: 2px;

        &:hover {
          padding-left: 2px;
          color: #2bc0af;
        }

        & svg {
          color: #f7bd52cc;
        }
      }
    }
  }

  & .nsf_bottom {
    border-top: 2px solid #f7bd52cc;
    padding-top: 20px;
    display: flex;
    justify-content: space-around;

    & p {
      color: #fff;
      font-weight: 600;
    }

    & .pp_sec {
      & a {
        color: #fff;
        font-weight: 600;

      }
    }
  }
}

/* SUBSCRIBE MAIL */

.form-subscribe .form-control {
  background-color: hsla(0, 0%, 100%, .8);
  padding-left: 10px;
  padding-right: 10px;
  border-top-left-radius: 36px;
  border-bottom-left-radius: 36px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.form-subscribe .form-control.focus,
.form-subscribe .form-control:focus {
  z-index: 2;
  background-color: hsla(0, 0%, 100%, .8)
}

.form-subscribe .btn {
  border-top-right-radius: 36px;
  border-bottom-right-radius: 36px;
  background: #C8A454;
  border-color: #C8A454;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

@media (max-width:767px) {
  .new_sion_footer {
    & .nsf_top {
      & .new_contact_sec {
        flex-direction: column;
      }
    }

    & .nsf_bottom {
      flex-direction: column;
    }
  }

}

.fblog_p {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

/* Seo Page */
.seopg_heading {
  text-align: center;
  font-weight: 700;
  font-size: 27px;
  padding: 10px;
}

.card.seocrd {
  width: 325px;
  box-shadow: 0px 1px 1px 0px white;
}

.scardhead {
  background-color: #0a99cb7d;
  box-shadow: 0px 1px 1px 1px rgb(231 228 228);
}

.spgetext {
  border-bottom: 1px solid lightgray;
  line-height: 2;
  border-radius: 0px 0 12px 2px;

  &:hover {
    box-shadow: 0px 1px 1px 1px rgb(231 228 228);
  }
}

/* 2D /3D*/
.animation-2d3d {
  display: flex;
  justify-content: center;
  align-items: center;
}

img.animation-2d3d-image {
  width: 573px;
}

.cards-styles {
  box-shadow: rgb(0 0 0 / 7%) 0px 5px 15px;
}

.cards-styles1 {
  box-shadow: rgb(0 0 0 / 7%) 0px 5px 15px;
}

.bottom-animation-2d {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #363232;
  border-bottom: 5px solid #b9e1f0;
  border-radius: 5px;
}

.top-animation-2d {
  height: 190px;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
  transition: .5s;
}

.top-animation-2d-para {
  padding-top: 15px;
}

.cards-styles:before {
  content: '';
  position: absolute;
  height: 0;
  width: 100%;
  background: #0395c9;
  bottom: 0;
  left: 0;
  transition: .5s;
  z-index: -1;
}

.cards-styles:hover:before {
  height: 100%;
}

.cards-styles:hover {
  color: white;
}

.cards-styles1:before {
  content: '';
  position: absolute;
  height: 0;
  width: 100%;
  background: #0395c9;
  top: 0;
  right: 0;
  transition: .5s;
  z-index: -1;
}

.cards-styles1:hover:before {
  height: 100%;
}

.cards-styles1:hover {
  color: white;
}

.cards-styles1:hover .top-animation-2d-haddding {
  color: #fff;
}

.cards-styles:hover .top-animation-2d-haddding {
  color: #fff;
}

.animation-cards-style {
  justify-content: space-evenly;
}

h3.sion-animation-process {
  margin-bottom: 0px;
}

span.bottom-animation-line-draw {
  height: 0px;
  border: 2px solid #f77e53;
  width: 300px;
  border-radius: 10px;
}

.bottom-animation-line {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

strong.sion-animation-liist-items {
  border-top: 2px solid;
  border-bottom: 2px solid;
  color: #0395c9;
}

li.sion-animation-list {
  margin-top: 20px;
}

p.sion-animation-list-para {
  padding-top: 10px;
}

.animation-content-top .accordian-sec .card-header {
  margin-bottom: 0;
  background-color: rgb(0 0 0 / 0%) !important;
  border-bottom: 1px solid rgba(0, 0, 0, .125);
  padding: 18px 21px;
  border-top: none !important;
}

.animation-content-top .card {
  border: none !important;
}

.anim img {
  width: 100%;
  height: auto;
}

.animation-content-top .accordian-sec .mb-0>a:after {
  content: "\f138";
  font-family: "Font Awesome 5 Free";
  position: absolute;
  right: 0;
  font-weight: 600;
}

.animation-content-top .accordian-sec .mb-0>a[aria-expanded="true"]::after {
  content: "\f13a";
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
}

/* =====animation-card====== */
:root {
  --color1: #EDA978;
  --color2: #853300;
}

.demo {
  background-color: #eee;
}

.animation-cards .serviceBox {
  color: var(--color2);
  background: linear-gradient(to right bottom, #fff, #f2f2f2);
  font-family: 'Roboto', sans-serif;
  text-align: center;
  padding: 50px 0 0;
  border-radius: 20px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2), 0 0 0 5px #fff inset;
  position: relative;
  min-height: 350px;
}

.animation-cards .serviceBox:before {
  content: "";
  background: var(--color1);
  width: calc(100% - 55px);
  height: 6px;
  transform: translateX(-50%);
  position: absolute;
  top: 0;
  left: 50%;
}

.animation-cards .serviceBox .title {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0 25px 10px;
}

.animation-cards .serviceBox .description {
  color: #888;
  font-size: 15px;
  line-height: 25px;
  margin: 0 25px 25px;
  text-align: justify;
}

.animation-cards .serviceBox .service-icon {
  background: var(--color1);
  font-size: 35px;
  line-height: 70px;
  width: 70px;
  height: 70px;
  border-radius: 10px 30px 10px 10px;
  margin: 0 auto;
  position: absolute;
  bottom: 26px;
  left: 0;
  right: 0;
}

.animation-cards .serviceBox.yellow {
  --color1: #EDCA79;
  --color2: #815800;
}

.animation-cards .serviceBox.purple {
  --color1: #958DBE;
  --color2: #1A1F57;
}

.animation-cards .serviceBox.blue {
  --color1: #7FCCCE;
  --color2: #035D5D;
}

@media only screen and (max-width: 1199px) {
  .animation-cards .serviceBox {
    margin: 0 0 30px;
  }
}

.sion-animation-process-serction .e-commerce-service2-detail {
  text-align: justify;
}

.e-commerce-services .e-commerce-service2-detail {
  text-align: justify;
}

.e-commerce-service2-detail {
  text-align: justify;
}

.e-commerce-service2-detail {
  text-align: justify;
}

@media (max-width:767px) {
  .animation-cards .serviceBox .service-icon {
    display: none;
  }
}

@media (max-width:992PX) {
  .animation-cards .serviceBox .service-icon {
    display: none;
  }

}

/* ======================= */
/* *****************Carrer******************* */
.career-section .arrow {
  margin-right: 8px;
  font-size: 30px;
  cursor: pointer;
  fill: transparent;

  color: #F77E53;
}

.career-section .search-buttons {
  border: none;
  color: white;
  background-color: #F77E53;
  padding: 8px 10px;
  border-radius: 6px;
  font-size: 13px;
  font-weight: 600;
  margin-top: 14px;
}

#exampleModalLong {
  padding-left: 0px !important;
}
p.carrer_page_Oppourtunity {
  font-size: 22px;
  font-weight: 500;
}
.Oppourtunity_text.col-lg-6 {
  position: absolute;
  right: 135px;
  line-height: 2;
  background-color: rgb(245 245 245 / 71%);
  padding: 4px 24px;
  border-radius: 2px;
}
.career-section .job-card {
  padding: 20px 16px;
  background-color: #f2f2f2;
  border-radius: 8px;
  cursor: pointer;
  transition: .2s;
  box-shadow: 0 6px 6px -1px;
}

.career-section .job-card:hover {

  transform: scale(1.02);
}

.career-section .detail-button {
  background-color: #e1ebfb;
  color: rgb(48, 45, 45);
  font-size: 11px;
  font-weight: 500;
  padding: 6px 8px;
  border-radius: 4px;
  margin-inline: 3px;
}

.career-section .job-card-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 4px;
}

.career-section .card-buttons,
.card-buttons-msg {
  padding: 10px;
  width: 100%;
  font-size: 12px;
  cursor: pointer;
}

.career-section .card-buttons {
  margin-right: 12px;
}

/* 
/* blog css  */

.search-container {
  position: relative;
}

.search-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  float: right;
  transform: translateY(-50%);
  color: #555;
  /* Adjust the color as needed */
}

.r_blog {
  position: sticky;
  top: 16%;
}

.author-img img {
  width: 70px !important;
  height: auto;
  border-radius: 100%;
}

.font-medium {
  font-weight: 500;
}

.border-radius-new {
  border-radius: 20px;
}

img.comms {
  width: 40px !important;
  height: auto;
}

.blog-img img {
  display: block;
  max-width: 100%;
  height: auto;
}

.blog-details p {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 20px;
}

.blog-details .date {
  color: #727070;
  padding: 7px 0px;
}

.blog-details h4 {
  font-weight: 700;
  font-size: 22px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-right: 15px;
}

.blog-btn {
  color: #052963;
  padding-top: 7px;
  padding-bottom: 4px;
}

.blog-details .date i {
  color: #052963;
  font-size: 13px;
}

.blog-head h1 {
  font-weight: bold;
}

.blog-dis img {
  transition: 0.9s;

}

.blog-dis .blog-img {
  overflow: hidden;
}

.blog-dis:hover .blog-details h4 {
  color: #052963;

}

a:hover {
  list-style: none !important;
  text-decoration: none !important;
}

.blog-details-sec .blog-img img {
  width: 100%;
  max-height: 345px;
  object-fit: contain;
  border-radius: 12px;
}

.blog-details-sec #search-s {
  height: 65px;
  background: transparent;
  padding: 0 88px 0 22px;
}

#search-s:focus {
  color: #495057;
  background-color: #fff;
  border-color: #052963;
  outline: 0;
  box-shadow: none !important;
}

.blog-details-sec .s-btn {
  height: 65px;
  width: 70px;
  background: #052963;
  border: 0;
  color: #fff;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  border-radius: 0;
  z-index: 3;
}

.blog-details-sec .blog-detailsall h4 {
  font-weight: 700;
  font-size: 25px;
  padding: 7px 0px;
}

.blog-details-sec .blog-detailsall .date {
  color: #727070;
}

.blog-details-sec .blog-detailsall .date i {
  color: #052963;
  font-size: 13px;
}

.blog-details-sec .cap-content {
  padding: 20px;
  border-left: 4px solid #052963;
  background-color: #eeeeee;
  font-style: italic;
}

.blog-details-sec .b-list li a {
  color: #000;
}

.blog-details-sec .category-blog h4 {
  border-bottom: 1px solid #052963;
  padding-bottom: 12px;
  display: inline-block;
}

.blog-details-sec .b-list li {
  padding: 8px 0px;
}

.blog-details-sec .category-blog {
  padding: 16px 0px;
}

.blog-details-sec .post-dis img {
  max-width: 70px;
  height: auto;
  object-fit: cover;
}

.blog-details-sec .post-dis {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ebe7e7;
}

.blog-details-sec .post-c {
  padding-left: 15px;
}

.blog-details-sec .post-c .date {
  font-size: 12px;
}

.blog-details-sec .post-c .date i {
  color: #052963;
}

.blog-details-sec .r-post h4 {
  border-bottom: 1px solid #052963;
  padding-bottom: 12px;
  display: inline-block;
}

.blog-details-sec .media-b {
  display: flex;
  justify-content: space-between;
}

.blog-details-sec .media-b h6 {
  font-weight: bold;
  padding-bottom: 5px;
  font-size: 17px;
}

.blog-details-sec .share-icon i {
  padding-right: 15px;
  color: #052963;
}

/* blog 1 sepearte section............................................................................. */
.blog-one .card-img-top {
  max-width: 75px;
}

.blog-one .card-alt {
  display: flex;
  /* box-shadow: 0 0 13px rgba(0, 0, 0, .345) inset; */
  box-shadow: 0 0 75px rgb(229 219 219 / 35%) inset;
  align-items: center;
  padding: 15px;
}

.blog-one .card-alt:hover {
  transform: scale(1.01);
}

.blog-one .card .card-text {
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.blog-one .card .card-text {
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.blog-one .card h4 {
  font-size: x-large;
}

.blog-one .con-head {
  color: #009CA6;

  position: relative;
}

.blog-one .con-head::after {
  content: "";
  position: absolute;
  border-bottom: 3px dashed #f77e53;
  top: 93%;
  left: -1%;
  width: 161px;
}

/* blog 2.................................................... */
.blog-two .card-img-top {
  max-width: 75px;
}

.blog-two .card-alt {
  display: flex;
  /* box-shadow: 0 0 13px rgba(0, 0, 0, .345) inset; */
  box-shadow: 0 0 75px rgb(229 219 219 / 35%) inset;
  align-items: center;
  padding: 15px;
}

.blog-two .card-alt:hover {
  transform: scale(1.01);
}

.blog-two .card .card-text {
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.blog-two .card .card-text {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.blog-two .card .card-head {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.blog-two .card h4 {
  font-size: x-large;
}

.blog-two .con-head {
  color: #009CA6;

  position: relative;
}

.blog-two .con-head::after {
  content: "";
  position: absolute;
  border-bottom: 3px dashed #f77e53;
  top: 93%;
  left: -1%;
  width: 161px;
}

/* accordian */

.blog-two .accordian .card-header,
.blog-two .accordian .card-link,
.blog-two .accordian .card-link:hover {
  background-color: #fff;
  text-align: left;
  color: #000;
}

.blog-two .accordian .card-body {
  padding: 10px 10px;
}

.blog-two .accordian .card {
  width: 100%;
  box-shadow: 0 10px 30px rgb(65 72 126 / 15%);
}

.blog-two .accordian .card-link:after {
  content: ">";
  position: relative;
  float: right;
  margin-top: -8px;
  margin-left: 5px;
  font-size: 20px;
  font-weight: 600;
  color: #000;
  -webkit-animation: minus 0.8s;
  animation: minus 0.8s;
}

.blog-two .accordian .card-link.collapsed:after {
  content: ">";
  -webkit-animation: plus 0.8s;
  animation: plus 0.8s;
}

@keyframes minus {
  0% {
    transform: rotate(360deg);
  }

  0% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes plus {
  0% {
    transform: rotate(-360deg);
  }

  0% {
    -webkit-transform: rotate(-360deg);
  }
}

/* blog 3................................. */
.blog-three .card-img-top {
  max-width: 75px;
}

.blog-three .card-alt {
  display: flex;
  /* box-shadow: 0 0 13px rgba(0, 0, 0, .345) inset; */
  box-shadow: 0 0 75px rgb(229 219 219 / 35%) inset;
  align-items: center;
  padding: 15px;
}

.blog-three .card-alt:hover {
  transform: scale(1.01);
}

.blog-three .card .card-text {
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.blog-three .card .card-head {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.blog-three .card h4 {
  font-size: x-large;
}

.blog-three .con-head {
  color: #009CA6;

  position: relative;
}

.blog-three .con-head::after {
  content: "";
  position: absolute;
  border-bottom: 3px dashed #f77e53;
  top: 93%;
  left: -1%;
  width: 161px;
}

/* accordian */

.blog-three .accordian .card-header,
.blog-three .accordian .card-link,
.blog-three .accordian .card-link:hover {
  background-color: #fff;
  text-align: left;
  color: #000;
}

.blog-three .accordian .card-body {
  padding: 10px 10px;
}

.blog-three .accordian .card {
  width: 100%;
  box-shadow: 0 10px 30px rgb(65 72 126 / 15%);
}

.blog-three .accordian .card-link:after {
  content: ">";
  position: relative;
  float: right;
  margin-top: -8px;
  margin-left: 5px;
  font-size: 20px;
  font-weight: 600;
  color: #000;
  -webkit-animation: minus 0.8s;
  animation: minus 0.8s;
}

.blog-three .accordian .card-link.collapsed:after {
  content: ">";
  -webkit-animation: plus 0.8s;
  animation: plus 0.8s;
}

@keyframes minus {
  0% {
    transform: rotate(360deg);
  }

  0% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes plus {
  0% {
    transform: rotate(-360deg);
  }

  0% {
    -webkit-transform: rotate(-360deg);
  }
}

/* blog4............................. */
.blog-four .card-img-top {
  max-width: 75px;
}

.blog-four .card-alt {
  display: flex;
  box-shadow: 0 0 75px rgb(229 219 219 / 35%) inset;
  align-items: center;
  padding: 15px;
}

.blog-four .card-alt:hover {
  transform: scale(1.01);
}

.blog-four .card .card-text {
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.blog-four .card .card-head {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.blog-four .card h4 {
  font-size: x-large;
}

.blog-four .con-head {
  color: #009CA6;

  position: relative;
}

.blog-four .con-head::after {
  content: "";
  position: absolute;
  border-bottom: 3px dashed #f77e53;
  top: 93%;
  left: -1%;
  width: 161px;
}

/* accordian */

.blog-four .accordian .card-header,
.blog-four .accordian .card-link,
.blog-four .accordian .card-link:hover {
  background-color: #fff;
  text-align: left;
  color: #000;
}

.blog-four .accordian .card-body {
  padding: 10px 10px;
}

.blog-four .accordian .card {
  width: 100%;
  box-shadow: 0 10px 30px rgb(65 72 126 / 15%);
}

.blog-four .accordian .card-link:after {
  content: ">";
  position: relative;
  float: right;
  margin-top: -8px;
  margin-left: 5px;
  font-size: 20px;
  font-weight: 600;
  color: #000;
  -webkit-animation: minus 0.8s;
  animation: minus 0.8s;
}

.blog-four .accordian .card-link.collapsed:after {
  content: ">";
  -webkit-animation: plus 0.8s;
  animation: plus 0.8s;
}

@keyframes minus {
  0% {
    transform: rotate(360deg);
  }

  0% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes plus {
  0% {
    transform: rotate(-360deg);
  }

  0% {
    -webkit-transform: rotate(-360deg);
  }
}

.blog-four .testimonial .card-title {
  color: #262626;
  font-size: 1.5em;
  line-height: normal;
  font-weight: 700;
  margin-bottom: 0.5em;
}

.blog-four .testimonial .small-desc {
  font-size: 1em;
  font-weight: 400;
  line-height: 1.5em;
  color: #452c2c;
}

.blog-four .testimonial .small-desc {
  font-size: 1em;
}

.blog-four .testimonial .go-corner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 2em;
  height: 2em;
  overflow: hidden;
  top: 0;
  right: 0;
  background: linear-gradient(135deg, #6293c8, #384c6c);
  border-radius: 0 4px 0 32px;
}

.blog-four .testimonial .go-arrow {
  margin-top: -4px;
  margin-right: -4px;
  color: white;
  font-family: courier, sans;
}

.blog-four .testimonial .card {
  display: block;
  position: relative;
  max-width: 300px;
  max-height: 320px;
  background-color: #f2f8f9;
  border-radius: 10px;
  padding: 2em 1.2em;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  background: linear-gradient(to bottom, #c3e6ec, #a7d1d9);
  font-family: Arial, Helvetica, sans-serif;
}

.blog-four .testimonial .card::before {
  content: '';
  position: absolute;
  z-index: -1;
  top: -16px;
  right: -16px;
  background: linear-gradient(135deg, #364a60, #384c6c);
  height: 32px;
  width: 32px;
  border-radius: 32px;
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: transform 0.35s ease-out;
}

.testimonial .card:hover::before {
  transform: scale(28);
}

.testimonial .card:hover .small-desc {
  transition: all 0.5s ease-out;
  color: rgba(255, 255, 255, 0.8);
}

.testimonial .card:hover .card-title {
  transition: all 0.5s ease-out;
  color: #ffffff;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: #f77e53;
  font-weight: bold;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #009CA6 !important;
  font-weight: bold;
}

/* blog css */
.author-img img {
  width: 70px !important;
  height: auto;
  border-radius: 100%;
}

.font-medium {
  font-weight: 500;
}

.border-radius-new {
  border-radius: 20px;
}

img.comms {
  width: 40px !important;
  height: auto;
}

.blog-img img {
  display: block;
  max-width: 100%;
  height: auto;
}

.blog-details p {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 20px;
}

.blog-details .date {
  color: #727070 !important;
  padding: 7px 0px;
}

.blog-details h4 {
  font-weight: 700;
  font-size: 22px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-right: 15px;
}

.blog-btn {
  color: orangered;
  padding-top: 7px;
  padding-bottom: 4px;
}

.blog-details .date i {
  color: #052963;
  font-size: 13px;
}

.blog-head h1 {
  font-weight: bold;
}

.blog-dis img {
  transition: 0.9s;
  padding: 6px 3px;
}

.blog-dis .blog-img {
  overflow: hidden;
}

.blog-dis:hover .blog-img img {
  transform: rotate(1deg) scale(1.05);
}

.blog-dis:hover .blog-details h4 {
  color: white;

}

a:hover {
  list-style: none !important;
  text-decoration: none !important;
}

/* blog section starts */
.blog-details-sec .blog-img img {
  width: 100%;
  max-height: 345px;
  object-fit: contain;
  border-radius: 12px;
}

.blog-details-sec #search-s {
  height: 65px;
  background: transparent;
  padding: 0 88px 0 22px;
}

#search-s:focus {
  color: #495057;
  background-color: #fff;
  border-color: #052963;
  outline: 0;
  box-shadow: none !important;
}

.blog-details-sec .s-btn {
  height: 65px;
  width: 70px;
  background: #052963;
  border: 0;
  color: #fff;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  border-radius: 0;
  z-index: 3;
}

.blog-details-sec .blog-detailsall h4 {
  font-weight: 700;
  font-size: 25px;
  padding: 7px 0px;
}

.blog-details-sec .blog-detailsall .date {
  color: #727070;
}

.blog-details-sec .blog-detailsall .date i {
  color: #052963;
  font-size: 13px;
}

.blog-details-sec .cap-content {
  padding: 20px;
  border-left: 4px solid #052963;
  background-color: #eeeeee;
  font-style: italic;
}

.blog-details-sec .b-list li a {
  color: #000;
}

.blog-details-sec .category-blog h4 {
  border-bottom: 1px solid #052963;
  padding-bottom: 12px;
  display: inline-block;
}

.blog-details-sec .b-list li {
  padding: 8px 0px;
}

.blog-details-sec .category-blog {
  padding: 16px 0px;
}

.blog-details-sec .post-dis img {
  max-width: 70px;
  height: auto;
  object-fit: cover;
}

.blog-details-sec .post-dis {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ebe7e7;
}

.blog-details-sec .post-c {
  padding-left: 15px;
}

.blog-details-sec .post-c .date {
  font-size: 12px;
}

.blog-details-sec .post-c .date i {
  color: #052963;
}

.blog-details-sec .r-post h4 {
  border-bottom: 1px solid #052963;
  padding-bottom: 12px;
  display: inline-block;
}

.blog-details-sec .media-b {
  display: flex;
  justify-content: space-between;
}

.blog-details-sec .media-b h6 {
  font-weight: bold;
  padding-bottom: 5px;
  font-size: 17px;
}

.blog-details-sec .share-icon i {
  padding-right: 15px;
  color: #052963;
}

.blog-acn .card-header,
.card-link,
.card-link:hover {
  text-align: left;
  color: rgb(18, 15, 17);
}

.blog-acn .card-body {
  padding: 10px 10px;
}

.blog-acn .card {

  border: none;
}

.blog-acn ul li {
  list-style: none;
}

.blog-acn .card-link:after {
  content: "x";
  position: relative;
  float: right;
  margin-top: -8px;
  margin-left: 5px;
  font-size: 20px;
  font-weight: 600;
  color: gray;
  -webkit-animation: minus 0.8s;
  animation: minus 0.8s;
  text-align: center;
}

.blog-acn .card-link.collapsed:after {
  content: ">";
  -webkit-animation: plus 0.8s;
  animation: plus 0.8s;
}

@keyframes minus {
  0% {
    transform: rotate(360deg);
  }

  0% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes plus {
  0% {
    transform: rotate(-360deg);
  }

  0% {
    -webkit-transform: rotate(-360deg);
  }
}

.blog-acn-card .data-main {
  width: 100%;

}

.blog-acn-card .Data .acn-data-img img {
  width: 70px;
  height: 70px;
}

.blog-acn-card .row .Data {
  padding: 25px 15px;
  background: transparent;
  font-size: 15px;
  border-radius: 2px;
  text-align: center;
  cursor: pointer;
  box-shadow: 0 0 13px rgba(0, 0, 0, 0.345);
  transition: 0.5s;
}

.blog-acn-card .row .Data:hover {
  transform: scale(1.01);
}

.blog-acn-card .row .Data h2 {
  font-weight: 600;
  margin-bottom: 20px;
}

.blog-acn-card .row .Data p {
  margin-bottom: 1rem;
  letter-spacing: 1px;
  line-height: 2;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

/* blog 2 start */

.blog-ac-2 .card-header,
.card-link,
.card-link:hover {

  text-align: left;
  color: rgb(0, 0, 0);
}

.blog-ac-2 .card-body {
  padding: 10px 10px;
}

.blog-ac-2 .card {
  width: 100%;
  border: none;
  box-shadow: 0 10px 30px rgb(65 72 126 / 15%);
}

.blog-ac-2 .card-link:after {
  content: ">";
  position: relative;
  float: right;
  margin-top: -8px;
  margin-left: 5px;
  font-size: 20px;
  font-weight: 600;
  color: #000000;
  -webkit-animation: minus 0.8s;
  animation: minus 0.8s;
}

.blog-ac-2 .card-link.collapsed:after {
  content: ">";
  -webkit-animation: plus 0.8s;
  animation: plus 0.8s;
}

@keyframes minus {
  0% {
    transform: rotate(360deg);
  }

  0% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes plus {
  0% {
    transform: rotate(-360deg);
  }

  0% {
    -webkit-transform: rotate(-360deg);
  }
}

/* Calculator start */
body::-webkit-scrollbar {
  background-color: transparent;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.budget,
.main-heading {
  background-color: #f2f3f7;
}

.budget .services {
  width: 100%;
  height: auto;
}

.budget .one {
  width: 100%;
  height: auto;
  background-color: #ffffff80;
  border-radius: 10px;
  box-shadow: 1px 2px 5px rgba(43, 42, 42, 0.27), inset -20px -20px 60px rgba(255, 255, 255, 0.27);

}

.budget .drop {
  font-weight: bolder;
  border: none;
  text-align: center;
}

/* slider css */

.budget h2 h4 {
  position: absolute;
  top: 10px;
  color: #009CA6;
  font-size: x-large;

}

.budget .sec-one {
  display: flex;
  align-items: center;
  justify-content: center;
}

.budget .input_num {
  color: #F77E53;
  font-weight: bold;
}

/* slider css */
.budget .para {
  font-weight: bold;
  margin-top: 5px;
}

.budget .second-side {
  width: 100%;
  height: auto;
  background-color: #ffffff80;
  backdrop-filter: blur(6px);
  border-radius: 10px;
  box-shadow: 1px 2px 5px rgba(43, 42, 42, 0.225), inset -20px -20px 60px rgba(255, 255, 255, 0.225);
  padding: 20px 30px;
}

.budget .second-side .total {
  border-bottom: 1px dotted gray;

  border-top: 1px dotted gray;
  margin-top: 22%;
  padding-top: 10px;

}

.budget .second-side .inr-total {
  justify-content: space-between;
}

.budget .second-side .inner .head {
  margin: 15px;

}

.budget .btn {
  width: 100%;
  background-color: #009CA6;
  color: white;
  border-radius: 15px;
  padding: 1%;

}

.budget .btnn {
  margin-bottom: 3%;
  margin-top: 15%;
  background-color: #F77E53;
}

.budget .drop {
  display: flex;
}

.budget .img1 img {

  max-width: 100px;
  animation: beat 1s infinite alternate;
}

@keyframes beat {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.1);
  }
}

.budget .one .dropd .yes,
.no {
  font-size: large;
  font-weight: bold;
  color: #009CA6;
}

.budget .one .dropd .no {

  color: #F77E53;
}

.modal-header .close {
  padding: 0;
  margin: 0;
  width: 30px;
  height: 35px;

}

.budget .services .enter_modal {
  color: #F77E53;
}

.main-heading h1 {
  color: #F77E53;
  position: relative;
  padding: 10px 0;

}

.main-heading h1::after {
  content: "";
  position: absolute;
  height: 3px;
  width: 50%;
  left: 25%;
  bottom: 0%;
  background-color: #009CA6;
}

.main-heading h1::before {
  content: "";
  position: absolute;
  height: 3px;
  width: 50%;
  left: 25%;
  top: 0%;
  background-color: #009CA6;
}

.budget .services .hh6 {
  color: #009CA6;
  font-weight: bold;
  font-size: large;
}

.budget .services .hh66 {
  color: #F77E53;
  font-weight: bold;
  font-size: large;
  margin-left: 5px;
}

.fa-indian-rupee-sign {
  margin-right: 2px;
}

.budget .second-side .grand {
  border-top: 1px dotted gray;
  padding-top: 7px;
}

.budget .services .one .modal .cardd img {
  height: 70px;
  width: 70px;
  align-items: center;

}

.budget .services .one .modal .cardd {
  border-bottom: 1px dotted gray;
  justify-content: space-between;
}

.budget .services .one .logo {
  color: #F77E53;
  font-weight: bold;
}

.budget .services .one .doc {
  color: #F77E53;
  font-weight: bold;
}

@media (max-width:992px) {
  .main-heading h1 {
    font-size: x-large;
    padding: 10px 0;
  }

}

@media (max-width:767px) {
  .range-container {
    width: 100%;
  }

  h2 {
    text-align: center;
  }

  .special {
    margin-left: 20px;

  }

  .budget .para {
    font-size: small;
  }

  .budget .drop {
    margin-left: 22px;
  }

  .budget .dropd {
    top: -51 !important;
  }

  input[type='range'] {

    margin: 82px 0px;

  }

  .main-heading h1 {
    font-size: small;
    padding: 10px 0;
  }

}

/* text editor */
.editor button i {
  display: flex;
  justify-content: center;

}

.description h1 {
  color: #009CA6;
  font-size: xx-large;
}

.description {
  margin-bottom: 50px;
  padding: 10px;

  border-radius: 5px;
}

.description h1 {
  margin-bottom: 0;
}

.description p {
  font-size: 14px;
}

#document {
  margin: 25px 0;
  padding: 25px;
  font-size: 15px;
  line-height: 1.4;
  border-radius: 5px;
  border: 2px solid transparent;
  outline: none;
  height: 250px;
}

#document:hover,
#document:focus {
  border-color: #eee;
}

#document li {
  margin-bottom: 10px;
}

#document p img {
  float: left;
  max-width: 250px;
  width: 100%;
  height: auto;
  margin: 5px 5px 5px 0;
}

#document p img.right {
  float: right;
  margin: 5px 0 5px 5px;
}

font[size='1'] {
  font-size: 10px;
}

font[size='2'] {
  font-size: 12px;
}

font[size='3'] {
  font-size: 14px;
}

font[size='4'] {
  font-size: 16px;
}

font[size='5'] {
  font-size: 18px;
}

font[size='6'] {
  font-size: 21px;
}

font[size='7'] {
  font-size: 26px;
}

.editor {
  border: 1px solid #999;
  border-radius: 5px;
  padding: 10px;
  background: #eee;
}

.editor .hide {
  display: none;
}

.editor .divider {
  margin: 0 4px;
  display: inline-block;
}

.editor .divider:after {
  content: "|";
  color: #666;
}

.editor button {
  width: 25px;
  text-transform: uppercase;
  font-weight: 700;
}

.editor button[data-action='italic'] {
  font-style: italic;
}

.editor button[data-action='underline'] {
  text-decoration: underline;
}

.editor button:hover {
  background: #ccc;
  color: #fff;
}

.editor button,
.editor select {
  background: #fff;
  border: 1px solid #666;
  border-radius: 5px;
  height: 25px;
  margin: 5px 2px;
}

.editor button:hover,
.editor select:hover {
  cursor: pointer;
}

.context-menu {
  background: #fff;
  position: absolute;
  top: 0;
  left: 50%;
  padding: 15px;
  border: 1px solid #333;
}

#document {
  height: 250px;
}

/* text editor */

/* ................................upload........................ */

.blue--btn {
  background: #009CA6;
  border: 1px solid #009CA6;
  color: #fff;
  border-radius: 4px;
  padding: 4px 12px;
  cursor: pointer;
  margin-top: 15px;
}

.form__field {
  margin: 24px 0px;
  position: relative;
}

.form__title {
  font-size: 1.125rem;
  font-weight: 700;
}

.form__load-img li {
  margin-bottom: 4px;
}

.form__load-img ul {
  list-style: none;
}

.load__img-wrap {
  background-color: white;
  margin-top: 8px;
  width: 100%;
  position: relative;
}

.load__img-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #f9f9fb;
}
.content__wrapper {
  justify-content: space-evenly;
  align-items: center;
}

.upload__TipIcon {
  position: relative;
}

.upload__Tooltip {
  display: none;
  position: absolute;
  top: 27px;
  right: -152px;
  padding: 16px;
  color: #767676;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
  white-space: normal;
  z-index: 999999;
  background: #fff;
  box-shadow: 0 4px 12px rgb(0 0 0 / 12%);
  border-radius: 8px;
  width: 275px;
}

.upload__TipIcon:hover .upload__Tooltip {
  display: block;
}

.margin--top-16 {
  margin-top: 16px;
}

.error__msg {
  visibility: visible;
  color: #ed0101 !important;
  font-size: 13px;
  font-weight: 550;
  margin: 15px 0;
  line-height: 15px;
}

.hidden {
  display: none !important;
  visibility: hidden;
}

.upload__thumbnails {
  margin: 24px 0 32px;
}

.imgs__wrapper,
.item__imgs {
  display: flex;
}

.item__wrapper:nth-of-type(1) {
  margin-left: 0;
}

.item__wrapper {
  position: relative;
  margin-left: 1.5rem;
}

.remove__img {
  position: absolute;
  z-index: 999;
  left: 64px;
  top: -7px;
}

.remove__icon {
  background: #fff;
  box-shadow: 0 1px 5px rgb(0 0 0 / 30%);
  border-radius: 50% 50%;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border: none;
}

.content__wrapper.disable {
  color: #BABABA;
}

.content__wrapper.disable path {
  fill: #BABABA;
}

.content__wrapper.disable button {
  background: #BABABA;
  border: 1px solid #BABABA;
  cursor: inherit;
}

input[type=file]::file-selector-button {
  border: 2px solid #009CA6;
  padding: .2em .6em;
  border-radius: .2em;
  background-color: #009CA6;
  transition: 1s;
  color: white;
}

.image-row {
  display: flex;
  flex-wrap: nowrap;
  /* Prevent wrapping to the next line */
}

.image-preview-container {
  margin-right: 10px;
  /* Adjust the spacing between images */
}

.image-preview {
  width: 100px;
  height: 100px;
}

.cut-icon {
  cursor: pointer;
}

/* ******************************************* Our -team ***************************************** */
.box {
  border-radius: 10px;
  background: #fff;
  position: relative;
  overflow: hidden;
  text-align: center;
}

.box:before {
  position: absolute;
  content: '';
  left: 0px;
  top: 0px;
  width: 0px;
  height: 100%;
  border-radius: 10px;
  box-shadow: inset 0 0 25px rgba(0, 0, 0, 0.30);
  transition: 1s;
  background-image: linear-gradient(#3c70a4 0%, #64b2cd 100%);
}

.box:hover:before {
  width: 100%;
  background-position: 100% 100%;
}

.box:hover .image-wrapper {
  padding: 0;
}

.box:hover .box-desc {
  color: #fff;
}

.box:hover .social li a {
  background: #fff;
  background-image: none;
  color: #000;
}

.box:hover .social li a:hover {
  background: #1d1d1d;
  color: #fff;
}

.image-wrapper {
  position: relative;
  width: 210px;
  height: 210px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 50%;
  padding: 15px;
  transition: all 0.5s;
  box-shadow: inset 0px 0px 20px rgba(0, 0, 0, 0.20);
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-wrapper img {
  border-radius: 50%;
  transition: 1s;
}

.box-desc {
  position: relative;
}

ul.social {
  padding: 0;
}

ul.social li {
  display: inline-block;
  list-style-type: none;
}

ul.social li a {
  position: relative;
  width: 36px;
  height: 36px;
  background-image: linear-gradient(#3c70a4 0%, #64b2cd 100%);
  display: inline-block;
  line-height: 36px;
  border-radius: 50%;
  color: #fff;
  transition: all .5s;
}

.sion-team {
  font-size: 27px;
  font-weight: 700;
  color: #000 !important;
}

/* owl */
.owl-nav button {
  position: absolute;
  top: 50%;
  background-color: #000;
  color: #fff;
  margin: 0;
  transition: all 0.3s ease-in-out;
}

.owl-nav button.owl-prev {
  left: 0;
}

.owl-nav button.owl-next {
  right: 0;
}

.owl-dots {
  text-align: center;
  padding-top: 15px;
}

.owl-dots button.owl-dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  background: #ccc !important;
  margin: 0 3px;
}

.owl-dots button.owl-dot.active {
  background-color: #7a7575 !important;
}

.owl-dots button.owl-dot:focus {
  outline: none;
}

.owl-nav button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.38) !important;
}

.owl-nav span {
  font-size: 70px;
  position: relative;
  top: -5px;
}

.owl-nav button:focus {
  outline: none;
}

.staff {
  margin-bottom: 30px;
  position: relative;
  z-index: 0;
  transition: all 0.3s ease;
  box-shadow: rgba(110, 94, 94, 0.24) 0px 3px 8px;
}

.staff .img {
  height: 170px;
  width: 170px;
  display: block;
  background-position: top center;
  position: relative;
  border-radius: 50%;
  margin: 0 auto;
  border: 10px solid #fff6f6;
  z-index: 0;
  background-size: cover;

}

.staff .img:after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  content: '';
  opacity: 0;
  z-index: -1;
  transition: all 0.3s ease;
}

.staff .text {
  position: relative;
  background: #fff;
  padding: 110px 30px 30px 30px;
  border-radius: 4px;
  margin-top: -100px;
  z-index: -1;
  box-shadow: 0px 5px 25px -2px rgba(0, 0, 0, 0.01);
}

.staff .text h3,
.staff .text .h3 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0;
}

.staff .text .position {
  color: #064F93;
  display: block;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 12px;
  padding: 6px 0;
}

.staff .text p {
  color: #948c8c;
}

.staff .ftco-social {
  padding: 0;
}

.staff .ftco-social li {
  list-style: none;
  margin-right: 2px;
  display: inline-block;
}

.staff .ftco-social li a {
  width: 34px;
  height: 34px;
  background: #f77e53;
  color: #fff;
  border-radius: 50%;
  text-decoration: none;
}

.staff:hover .text,
.staff:focus .text {
  background: #258bc2;
  color: rgba(255, 255, 255, 0.8);
}

.staff:hover .text h3,
.staff:hover .text .h3,
.staff:focus .text h3,
.staff:focus .text .h3 {
  color: #fff;
}

.staff:hover .text .position,
.staff:focus .text .position,
.staff:hover .text p {
  color: #fff;
}

.staff:hover .ftco-social li a,
.staff:focus .ftco-social li a {
  background: #f77e53;
  color: #fff;
}

.box-area {
  padding-top: 30px;
}

.socials a {
  width: 40px;
  height: 40px;
  background: #F27C51;
  display: inline-block;
  transition: .9s;
  border-radius: 50%;

}

.socials a:hover {
  background: #fff;
}

.socials i {
  color: #fff;
  padding: 12px;
}

.socials a:hover i {
  color: rgb(214, 21, 21);
}

.line {
  height: 5px;
  width: 225px;
  background: #F27C51;
  margin: 20px 0;
}

.staff .card-header {
  background: #F27C51;

}

.card-body {
  background-color: white;
}

.card-body .ceo {
  position: relative;
  margin: 60px 0 0 60px;
  z-index: 1;
  text-align: center;
  padding: 0 30px;

}

.card-body p {
  line-height: 30px;
  padding: 20px 5px;
}

.card-body .ceo::before {
  content: "";
  position: absolute;
  background: url("../../public/assets/images/about-bg.png") top right;
  inset: -43px -50px 0 0;
  z-index: -1;
  background-repeat: no-repeat;
}

/*responsive css*/

@media (max-width: 767px) {

  .socials {
    margin-bottom: 30px;
  }

  .card-body .ceo {
    position: relative;
    margin: 22px 0 0 0;
    z-index: 1;
    text-align: center;
    padding: 0 25px;

  }

  .card-body .ceo::before {
    content: "";
    position: absolute;
    background: url("../../public/assets/images/about-bg.png") top right;
    inset: -43px -50px 0 0;
    z-index: -1;
    background-repeat: no-repeat;
  }

}

@media (max-width:992px) {
  .card-body .ceo {
    position: relative;
    margin: 125px 0 0 0;
    z-index: 1;
    text-align: center;
    padding: 0 0;

  }

  .card-body .ceo::before {
    content: "";
    position: absolute;
    background: url("../../public/assets/images/about-bg.png") top right;
    inset: -43px -50px 0 0;
    z-index: -1;
    background-repeat: no-repeat;
  }
}

/* *********************** ========Content start================********************** */
/* service */
.services .section-title h3 {
  font-size: 27px;
}

.services .icon-box {
  padding: 60px 30px;
  position: relative;
  overflow: hidden;
  background: #fff;
  box-shadow: 2px 0 35px 0 rgba(68, 88, 144, 0.12);
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  z-index: 1;
  text-align: center;
}

.services .icon-box::before {
  content: "";
  position: absolute;
  background: #fdeff2;
  right: 0;
  left: 0;
  bottom: 0;
  top: 100%;
  transition: all 0.4s;
  z-index: -1;
}

.services .icon-box:hover::before {
  background: #f77e53;
  top: 0;
  border-radius: 0px;
}

.services .icon {
  margin-bottom: 15px;
}

.services .icon i {
  font-size: 48px;
  line-height: 1;
  color: #f77e53;
  transition: all 0.3s ease-in-out;
}

.services .title {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
}

.services .title a {
  color: #141631;
  text-decoration: none;
}

.services .description {
  font-size: 15px;
  line-height: 28px;
  margin-bottom: 0;
}

.services .icon-box:hover .title a,
.services .icon-box:hover .description {
  color: #fff;
}

.services .icon-box:hover .icon i {
  color: #fff;
}

/* content Section*/

.content .content-img {
  position: relative;
  margin: 60px 0 0 60px;
}

.content .content-img:before {
  position: absolute;
  inset: -60px 0 0 -60px;
  z-index: -1;
  content: "";
  background: url("../../public/assets/images/about-bg.png") top left;
  background-repeat: no-repeat;
}

.content .content-img-1 {
  position: relative;
  margin: 60px 0 0 60px;
}

.content .content-img-1:before {
  position: absolute;
  inset: -60px -60px 0 0;
  z-index: -1;
  content: "";
  background: url("../../public/assets/images/about-bg.png") top right;
  background-repeat: no-repeat;
}

.content h3 {
  font-weight: 600;
  font-size: 31px;
  margin-bottom: 20px;

}

.content h4 {
  font-size: 18px;
  margin: 0;
  font-weight: 600;
}

.content i {
  font-size: 22px;
  line-height: 0;
  margin-right: 8px;
  color: #f77e53;
}

.animation-content-top h2 {
  font-size: 29px;
}

.animation-content-top .accordian-sec .card-header {
  margin-bottom: 0;
  background-color: rgb(0 0 0 / 0%) !important;
  border-bottom: 1px solid rgba(0, 0, 0, .125);
  padding: 18px 21px;
  border-top: none !important;
}

.animation-content-top .card {
  border: none !important;
}

.anim img {
  width: 100%;
  height: auto;
}

.animation-content-top .accordian-sec .mb-0>a:after {
  content: "\f138";
  font-family: "Font Awesome 5 Free";
  position: absolute;
  right: 0;
  font-weight: 600;
}

.animation-content-top .accordian-sec .mb-0>a[aria-expanded="true"]::after {
  content: "\f13a";
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
}

@media (max-width:992px) {
  .content .content-img-1 {
    margin: 30px 0 0 30px;
  }

  .content .content-img-1:before {
    inset: -30px 4px 0 0;
  }
}

@media (max-width: 767px) {
  .content .content-img {
    margin: 30px 0 0 30px;
  }

  .content .content-img:before {
    inset: -30px 0 0 -30px;
  }

  .content .content-img-1 {
    margin: 30px 30px 0 0;
  }

  .content .content-img-1:before {
    inset: -30px -30px 0 0;
  }

  .content h3 {
    font-size: 28px;
    margin-top: 20px;
  }
}

/* *********************===================job start===================************** */
.form {
  max-width: calc(100vw - 40px);
  width: 460px;
  height: auto;
  background: rgba(255, 255, 255, 1);
  border-radius: 8px;
  box-shadow: 0px 10px 10px -5px;
  margin: 3% auto;
  padding: 0px 30px;
  box-sizing: border-box;
  position: relative;
  border-bottom: 1px solid #ccc;
}

.form h2 {
  margin: 18px 0;
  padding-bottom: 10px;
  width: 210px;
  color: #1e439b;
  font-size: 22px;
  border-bottom: 3px solid #f77e53;
  font-weight: 600;
  margin-bottom: 30px;
}

.relative input {
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
  background: none;
  outline: none;
  resize: none;
}

.tright {
  text-align: center;
  background-color: #064e73;
  padding: 6px;
}

.tright:hover {
  background-color: #d95d30;
}

.relative {
  position: relative;
}

.form-control:focus {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgb(30, 102, 195);
}

/* internship */
.form1 {
  max-width: calc(100vw - 40px);
  width: 415px;
  height: auto;
  background: rgba(255, 255, 255, 1);
  border-radius: 8px;
  box-shadow: 0 0 40px -10px #fff;
  margin: 3% auto;
  padding: 0px 30px;
  box-sizing: border-box;
  position: relative;
  border-bottom: 1px solid #ccc;
}

@media only screen and (max-width: 767px) {
  .form1 {
    max-width: calc(100vw - 40px);
    width: 300px;
    height: auto;
    background: rgba(255, 255, 255, 1);
    border-radius: 8px;
    box-shadow: 0 0 40px -10px #fff;
    margin: 3% auto;
    padding: 0px 30px;
    box-sizing: border-box;
    position: relative;
    border-bottom: 1px solid #ccc;
  }
}

/* ***************==============Seo Start============***************************** */
h3.sion-seo-algo {
  font-size: 25px;
  text-align: center;
  padding-top: 30px;
  font-weight: 700;
}

p.sion-seo-algo-para {
  text-align: center;
}

.main-seo-rank1 {
  height: 270px;
  border-left: 2px solid;
  border-right: 2px solid;
}

.main-seo-rank2 {
  height: 270px;
  border-left: 2px solid;
  border-right: 2px solid;
}

.main-seo-rank1-top {
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

h5.main-seo-rank1-top-hadding {
  color: #000000;
  font-size: 17px;
}

p.main-seo-rank1-bottom-para1 {
  padding-left: 35px;
  color: #000000;
  text-align: center;
  padding-right: 35px;
}

p.main-seo-rank1-bottom-para2 {
  padding-right: 35px;
  color: #000000;
  text-align: center;
  padding-left: 35px;
}

.sion-seo-com {
  text-align: center;
  padding-top: 40px;
}

h5.seo-analyze {
  padding: 10px 0px 10px 10px;
  margin-top: 30px;
  background: rgb(225, 225, 225);
  background: linear-gradient(90deg, rgba(225, 225, 225, 1) 0%, rgba(255, 255, 255, 1) 100%);
  margin-bottom: 0px;
  font-size: 17px;
}

/* h5.seo-analyze {
    background: rgb(3,149,201);
    background: linear-gradient(90deg, rgba(3,149,201,1) 0%, rgba(255,255,255,1) 100%);
    padding: 10px 0px 10px 10px;
    margin-top: 30px;
    border-radius: 10px 0px 0px 10px;
} */

p.seo-site {
  padding-top: 10px;
}

li.seo-site-lists-items {
  list-style: inside;
}

ul.seo-site-lists {
  margin-top: 20px;
}

h4.sion-seo-busy {
  text-align: center;
  margin-bottom: 0px;
  font-size: 22px;
  padding-top: 30px;
  font-weight: 800;
}

img.img-fluid.seo-h-w {
  width: 479px;
}

.seo-he-wi {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

h3.sion-seo-get {
  margin-bottom: 0px;
  padding-top: 40px;
  text-align: center;
  font-size: 23px;
  font-weight: 800;
}

p.seo-knowledge {
  text-align: center;
  padding-top: 15px;
}

.seo-roi {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

h5.seo-brand-roi-head {
  font-weight: 800;
}

p.seo-end-para {
  padding-top: 15px;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  img.img-fluid.seo-h-w {
    width: 320px;
  }

  .main-seo-rank2 {
    height: auto;
  }

  .main-seo-rank1 {
    height: auto;
  }
}

.features .nav-tabs {
  border: 0;
}

.features .nav-link {
  border: 1px solid #d4d6df;
  padding: 15px;
  transition: 0.3s;
  color: #2a2c39;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.features .nav-link i {
  padding-right: 15px;
  font-size: 37px;
}

.features .nav-link h4 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

/* .features .nav-link:hover {
    color: #f27c51;
  }
   */
/* .features .nav-link.active {
    background: #f27c51;
    color: #fff;
    border-color: #f27c51;
  } */

@media (max-width: 768px) {
  .features .nav-link i {
    padding: 0;
    line-height: 1;
    font-size: 36px;
  }

  .features .nav-link h4 {
    font-size: 10px;

    margin: 0;
    overflow: scroll;
    white-space: nowrap;
  }
}

@media (max-width: 575px) {
  .features .nav-link {
    padding: 15px;
  }

  .features .nav-link i {
    font-size: 24px;
  }

}

.features .tab-content {
  margin-top: 30px;
}

.features .tab-pane h3 {
  font-weight: 600;
  font-size: 26px;
}

.features .tab-pane ul {
  list-style: none;
  padding: 0;
}

.features .tab-pane ul li {
  padding-bottom: 10px;
}

.features .tab-pane ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #f27c51;
}

.features .tab-pane p:last-child {
  margin-bottom: 0;
}

/* ====================************ui /ux start********======================== */
h2.uiux-service-hadding {
  text-align: center;
  font-size: 23px;
  font-weight: 700;
}

strong.uiux-head {
  border-top: 2px solid;
  border-bottom: 2px solid;
  color: #f27c51;
  padding: 3px 0px;
}

li.uiux-list-head {
  margin: 25px 0px 15px 0px;
}

li.uiux-list-head {
  list-style: none;
  position: relative;
  padding: 0 25px 0 25px;
  margin: 25px 0px 15px 0px;
}

li.uiux-list-head::before {
  content: "";
  position: absolute;
  left: 0;
  top: 6px;
  height: 13px;
  width: 13px;
  border: 1px solid #f27c51;
  border-width: 2px 2px 0 0;
  transform: rotate(45deg);
}

h4.uiux-diff {
  font-weight: 800;
  text-align: center;
  padding: 50px 0px 30px 0px;
}

.uiux-bottom-show-para {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

p.uiux-design-diff {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

li.uiux-design-diff-list {
  list-style: none;
  position: relative;
  padding: 0 25px 0 25px;
  margin: 25px 0px 15px 0px;
}

li.uiux-design-diff-list::before {
  content: "";
  position: absolute;
  left: 2px;
  top: 5px;
  width: 10px;
  height: 21px;
  border: 5px solid #f27c51;
  border-width: 0 5px 5px 0;
  transform: rotate(45deg);
}

.uiux-diff-list-st {
  color: #f27c51;
}

.uiux-design-amazing-top {
  height: 40px;
  display: flex;
  justify-content: left;
  align-items: center;
}

.uiux-design-amazing-bottom {
  height: 200px;
  text-align: justify;
}

.uiux-design-amazing-full {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 0px 35px;
  margin-top: 35px;
  padding: 0px 10px;
}

h5.uiux-design-amazing-top-head {
  margin-bottom: 0px;
  font-size: 16px;
  color: #F27C51;
}

.service-single-box {
  border-radius: 2px;
  background: #fff;
  box-shadow: 0px 0px 55px 0px rgba(0, 0, 0, 0.10);
  padding: 15px 15px 64px;
  position: relative;
  z-index: 1;
  margin-bottom: 20px;
  min-height: 461px;
}

.service-icon {
  margin-bottom: 23px;
}

.service-title h4 a {
  color: #20292F;
  font-size: 21px;
  font-weight: 500;
  text-decoration: none;
  transition: .5s;
}

.underline .br-line {
  position: relative;
  overflow: hidden;
  height: 2px;
  width: 57px;
  background: #0154F7;
  left: 0;
  top: 20px;
  z-index: 1;
  transition: .5s;
}

.serivice-discription p {
  color: #666;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin: 43px 0 0;
  transition: .5s;
  text-align: justify;
}

.service-icon img {
  max-width: 80px;
  height: auto;
}

/* ==============card-content-sec-start========== */
:root {
  --main-color: #FF421E;
}

.uiux-sec .serviceBox {
  color: var(--main-color);
  font-family: 'Poppins', sans-serif;
  text-align: center;
  padding: 35px 28px 25px;
  position: relative;
  z-index: 1;
  min-height: 342px;
}

.uiux-sec .serviceBox:before,
.uiux-sec .serviceBox:after {
  content: "";
  border-radius: 25px;
  border: 10px solid var(--main-color);
  clip-path: polygon(65% 0, 100% 0, 100% 35%, 35% 100%, 0 100%, 0 65%);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.uiux-sec .serviceBox:after {
  border-width: 3px;
  clip-path: polygon(0 0, 60% 0, 55% 5%, 96% 44%, 100% 40%, 100% 100%, 40% 100%, 43% 95%, 5% 55%, 0 60%);
  top: 3px;
  bottom: 3px;
  right: 3px;
  left: 3px;
}

.uiux-sec .serviceBox .service-icon {
  font-size: 40px;
  margin: 0 0 10px;
}

.uiux-sec .serviceBox .title {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: capitalize;
  margin: 0 0 10px;
}

.uiux-sec .serviceBox .description {
  color: #555;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 22px;
  margin: 0s;
}

.uiux-sec .serviceBox.blue {
  --main-color: #0bb7ce;
}

@media only screen and (max-width: 1199px) {
  .serviceBox {
    margin: 0 0 40px;
  }
}

/* ==============end======================= */

@media only screen and (max-width: 767px) {
  .digitalh-w {
    width: 320px;
    height: auto;
  }
}

/* =========================***********Video Editing**********========================================== */
.sion-video-top {
  box-shadow: 1px 0px 34px #efefef;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 345px;
  height: 345px;
}

p.sion-video-paragraph {
  font-size: 13px;
  text-align: justify;
  color: #565454;
  padding: 0px 57px;
}

/* .sion-video-top-para {
  width: 271px;
  height: 210px;
} */
h5.sion-video-headding {
  font-size: 18px;
  color: #F27C51;
  text-align: center;
}

.sion-video-top-btm {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sion-video-top-btmm1 {
  padding-left: 210px;
}

.sion-video-top-btmm2 {
  padding-left: 50px;
}

h5.sion-videoed-headding {
  font-size: 20px;
  text-align: center;
  font-weight: 800;
  margin: 50px 0px 30px 0px;
}

p.sion-videoed-paragraph {
  text-align: -webkit-center;
}

/* span.sion-videoed-service {
  border-bottom: 4px solid #F27C51;
} */

.main-videosec-top {
  display: flex;
  margin-top: 10px;
}

.main-videosec-top1 {
  background-image: url(../../public/assets/images/videostyle.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-videosec-top2 {
  display: flex;
  align-items: center;
}

strong.videosec-top2-head {
  border-top: 2px solid #F77E53;
  border-bottom: 2px solid #F77E53;
  padding: 2px 0px;
  color: #F77E53;
}

p.main-videosec-bottom-para {
  padding-left: 80px;
}

p.main-videosec-num {
  font-weight: 900;
}

.main-videosec-top1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

p.video-service-bottom {
  text-align: center;
  padding-top: 30px;
}

:root {
  --main-color: #FF421E;
}

.uiux-sec .serviceBox {
  color: var(--main-color);
  font-family: 'Poppins', sans-serif;
  text-align: center;
  padding: 35px 28px 25px;
  position: relative;
  z-index: 1;
  min-height: 420px;
}

.uiux-sec .serviceBox:before,
.uiux-sec .serviceBox:after {
  content: "";
  border-radius: 25px;
  border: 10px solid var(--main-color);
  clip-path: polygon(65% 0, 100% 0, 100% 35%, 35% 100%, 0 100%, 0 65%);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.uiux-sec .serviceBox:after {
  border-width: 3px;
  clip-path: polygon(0 0, 60% 0, 55% 5%, 96% 44%, 100% 40%, 100% 100%, 40% 100%, 43% 95%, 5% 55%, 0 60%);
  top: 3px;
  bottom: 3px;
  right: 3px;
  left: 3px;
}

.uiux-sec .serviceBox .service-icon {
  font-size: 40px;
  margin: 0 0 10px;
}

.uiux-sec .serviceBox .title {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: capitalize;
  margin: 0 0 10px;
}

.uiux-sec .serviceBox .description {
  color: #555;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 22px;
  margin: 0s;
}

.uiux-sec .serviceBox.blue {
  --main-color: #0bb7ce;
}

@media only screen and (max-width: 767px) {
  .sion-video-top-btmm1 {
    padding-left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sion-video-top-btmm2 {
    padding-left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .main-videosec-top1 {
    width: 60px;
    height: 60px;
  }

  p.main-videosec-bottom-para {
    padding-left: 0px;
  }
}

/* =================****************Website Development********************============================== */
.web .img-top img {
  width: 100%;
  height: 350px;
  object-fit: cover;
}

.sec-icon {
  position: relative;
  display: inline-block;
  padding: 0;
  margin: 0 auto;
}

.advertisers-service-sec .service-card {
  width: 100%;
  height: 100%;
  padding: 2em 1.5em;
  border-radius: 5px;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  transition: 0.5s;
  position: relative;
  z-index: 2;
  overflow: hidden;
  background: #fff;
}

.advertisers-service-sec .service-card::after {
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(#0dcaf0, #ff7350);
  position: absolute;
  left: 0%;
  top: -98%;
  z-index: -2;
  transition: all 0.4s cubic-bezier(0.77, -0.04, 0, 0.99);
}

.advertisers-service-sec .head {
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 600;
  color: #1f194c;
  margin: 1em 0;
  z-index: 3;
}

.advertisers-service-sec .icon-wrapper i {
  width: 80px;
  height: 80px;
  font-size: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.advertisers-service-sec .icon-wrapper {

  position: relative;
  margin: auto;
  font-size: 30px;
  height: 90px;
  width: 90px;
  border-radius: 50%;
  transition: 0.5s;
  z-index: 3;
}

.advertisers-service-sec .service-card:hover:after {
  top: 0%;
}

.service-card .icon-wrapper {

  color: #ff7350;
}

.advertisers-service-sec .service-card:hover .icon-wrapper {
  color: white;
}

.advertisers-service-sec .service-card:hover h3 {
  color: #ffffff;
}

.advertisers-service-sec .service-card:hover p {
  color: #f0f0f0;
}

.advertisers-service-sec .dev-content h5 i {
  color: #1f194c;
  font-size: 16px;
}

.advertisers-service-sec .main-img img {
  width: 100%;
  height: 512px;
  object-fit: cover;
}

.Additional-header {
  position: relative;
  padding: 5px;
}

.Additional {
  padding: 5px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.17) 0px 3px 8px;
  height: 270px;
}

.blog-page .Additional-header .im-1 {
  width: 100%;
  height: 90px;
  object-fit: contain;
  transition: 0.5s;

}

.blog-page .Additional-title {
  font-size: 20px;
  font-weight: 700;
}

.Additional:hover {
  box-shadow: rgba(0, 0, 0, 0.323) 0px 3px 8px;
  transition: .3s all ease;
}

.Additional:hover .im-1 {
  transform: scale(1.1);
  overflow: hidden;
  transition: .3s all ease-in;
}

/* blogs css */
:root {
  --white: #fff;
  --color-heading: #04000b;
  --color-paragraph: #787878;
  --color-primary: #0c5adb;
  --color-secondary: #3f1399;
  --bg-gray: #f3f7fd;
  --font-default: 'Yantramanav', sans-serif;
  --bg-gradient: linear-gradient(30deg, var(--color-secondary) 20%, var(--color-primary) 80%);
  --background-color: #064e73;
}

.services-style-one p {
  color: var(--color-paragraph);
  margin: 0 0 15px;
  text-transform: none;
  font-weight: 400;
  font-family: var(--font-default);
  line-height: 1.7;
}

/* section-1-start */
.thumb_img img {
  width: 100%;
  height: 100%;
}

.digital_offer_section {
  background-color: var(--background-color);
}

.b_discription .title {
  font-weight: 700;
  font-size: 40px;
  line-height: 1.1;
  color: #fff;
}

.sub-title_line {
  color: #f77e53;
}

.sub-title {
  text-transform: uppercase;
  color: var(--color-primary);
  font-weight: 500;
  background: var(--bg-gradient);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  position: relative;
  z-index: 1;
}

.title_para {
  color: #bdbdbd;
  ;
}

/* end */

/* section-2 */
.bg-gray {
  background: var(--bg-gray);
}

.default-padding-top {
  padding-top: 60px;
}

.services-tab-navs .nav-tabs {
  display: block;
  background: var(--white);
  padding: 40px 20px;
  position: relative;
  padding-bottom: 50px;
  top: -80px;
  border: none;
  margin-bottom: -50px;
  border-radius: 8px;
  box-shadow: 0 5px 11px rgba(3, 27, 78, .08);
}

.services-tab-navs .nav-tabs .nav-link:first-child {
  margin-top: 0;
}

.services-tab-navs .nav-tabs .nav-link.active {
  border-color: #dddddd;
}

.services-tab-navs .nav-tabs .nav-link {
  margin: 0;
  padding: 15px 0;
  width: 100%;
  text-align: left;
  border: none;
  border-bottom: 1px solid #f1f1f1;
  color: var(--color-heading);
  font-size: 20px;
  font-weight: 600;
  transition: all 0.35s ease-in-out;
  position: relative;
  display: flex;
  align-items: center;
  position: relative;
}

.services-tab-navs .nav-tabs .nav-link.active::before {
  opacity: 1;
  visibility: visible;
}

.services-tab-navs .nav-tabs .nav-link::before {
  position: absolute;
  content: "";
  top: 20px;
  right: -59px;
  height: 40px;
  width: 40px;
  border-left: solid var(--white);
  border-top: solid transparent;
  border-bottom: solid transparent;
  border-width: 20px;
  -webkit-filter: drop-shadow(3px 0 0 rgba(3, 27, 78, .03));
  filter: drop-shadow(3px 0 0 rgba(3, 27, 78, .03));
  transition: all .4s ease;
  visibility: hidden;
  opacity: 0;
}

.services-tab-navs .nav-tabs .nav-link.active i {
  color: var(--color-primary);
}

.services-tab-navs .nav-tabs .nav-link i {
  display: block;
  font-size: 55px;
  margin-right: 20px;
  position: relative;
  top: 5px;
}

.services-tab-navs .nav-tabs .nav-link b {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  color: var(--color-paragraph);
  letter-spacing: 0.6px;
  display: block;
  margin-bottom: 0;
}

.services-style-one .thumb {
  position: relative;
  z-index: 1;
}

.services-style-one .thumb::after {
  position: absolute;
  right: -100px;
  top: -70px;
  content: "";
  height: 300px;
  width: 300px;
  background: linear-gradient(20deg, transparent, rgba(255, 255, 255, 1));
  z-index: -1;
  border-radius: 50%;
}

.services-style-one .info {
  position: relative;
  z-index: 1;
}

.services-style-one .info h2 {
  font-weight: 600;
  margin-bottom: 25px;
}

ul.list-standard {
  margin: 0;
  padding: 0;
  list-style: none;
}

.list-standard li {
  position: relative;
  font-size: 15px;
  padding-left: 30px;
  line-height: 2;
  font-weight: 500;
  color: var(--color-paragraph);
}

.btn.circle {
  border-radius: 30px !important;
}

.btn.btn-gradient {
  border: none;
  color: var(--white);
}

.btn-md {
  padding: 16px 52px;
  font-size: 17px;
}

.services-style-one .btn {
  display: inline-block;
  font-weight: 500;
  text-transform: capitalize;
  transition: all 0.35s ease-in-out;
  overflow: hidden;
  border-radius: 8px;
  border: 2px solid transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 16px;
  letter-spacing: 0;
  padding: 13px 30px;
  background: #E7EDF8;
  position: relative;
  z-index: 1;
  margin-top: 12px;
}

.btn.btn-gradient::after {
  position: absolute;
  top: 0;
  right: inherit;
  bottom: inherit;
  left: 0;
  content: "";
  height: 100%;
  width: 100%;
  z-index: -1;
  background-image: linear-gradient(to right, var(--color-primary), var(--color-secondary), var(--color-primary));
  background-size: 220% 150%;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.thumb img {
  width: 100%;
  height: 100%;
}

.list-standard li i {
  margin-right: 10px;
  color: #064e73;
}

.services-tab-navs .nav-link .icon img {
  max-width: 60px;
  height: auto;
  margin-right: 15px;
}

.nav-tabs .nav-link.active {
  color: #0293cc !important;
}

/* blog end */

/* =====================***************Custome csss*****************=============================== */
#hero {
  width: 100%;
  background: #ffffff;
}

#hero h1 {
  margin: 0;
  font-size: 27px;
  font-weight: 700;
  line-height: 35px;
  color: #141631;
  text-transform: uppercase;
}

#hero p {
  color: #141631;
  margin: 10px 0 0 0;
  font-size: 14px;
  line-height: 22px;
  text-align: justify;
}

/* ========================*******************Responsive *****************====================== */
@media only screen and (max-width: 1440px) {
  .topnav {
    display: none;
  }

  .sion-services-web {
    width: 296px;
  }
}

@media only screen and (max-width: 1200px) {
  .sdmi-about-image-sec::before {
    left: -10px;
  }
}

@media only screen and (max-width: 1024px) {
  p.main-web-content {
    font-size: 30px;
    margin-top: 5px;
  }

  .web-about-sec-top {
    left: 30%;
  }

  .col-md-6.web-about-right-section {
    gap: 2rem;
  }

  .service-more-btn {
    margin-left: 0rem;
    margin-bottom: 0px;
  }

  p.af-btm-sec-agter-hadd {
    font-size: 29px;
  }

  p.af-btm-sec-clients10 {
    font-size: 28px;
  }

  .sion-bottom-footer {
    padding: 10px 49px;
  }

  .Hero-section {
    padding: 0px 70px;
  }

  .web-best-section1 {
    width: 255px;
    height: 110px;
    gap: 10px;
  }

  .web-best-section2 {
    width: 255px;
    height: 110px;
    gap: 10px;
  }

  .web-best-section3 {
    width: 255px;
    height: 110px;
    gap: 10px;
  }

  p.web-best-section-counting {
    font-size: 20px;
  }

  p.web-best-section-details {
    font-size: 18px;
  }

  ul.header-sec-sion21 {
    gap: 25px;
  }

  a.header-sec-sion-listitems {
    font-size: 16px;
    font-weight: 600;
  }

  .sion-navs-button {
    display: none;
  }

  .navigation-sdmi {
    gap: 0px;
  }

}

@media only screen and (max-width: 992px) {

  .olcards {
    flex-direction: column;
  }

  p.main-web-content {
    font-size: 25px;
    margin-top: 5px;
  }

  .sion-services-web-sections {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Hero-section {
    padding: 0px 50px;
  }

  .main-top-nav {
    justify-content: center;
    padding: 10px 80px;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
  }

  .main-top-nav {
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    flex-direction: column;
  }

  a.header-sec-sion-listitems {
    font-size: 15px;
    font-weight: 600;
  }

  .header-sec-sion3 {
    width: 175px;
  }

  p.web-clg-how-work {
    font-size: 18px;
    font-weight: 400;
  }

  section.nav-sec {
    position: sticky;
    top: 0px;
    z-index: 999;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 0px 0px 0px 0px;
  }

  /* Contact */
  .contact-bottom-sec {
    padding: 50px 20px;
  }

  /* common  */
  p.e-commerce-services-hadding {
    font-size: 20px;
  }

  .e-commerce-services {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  p.e-commerce-service2-detail {
    font-size: 15px;
    padding-top: 10px;
  }

  button.e-commerce-service3-btn {
    width: 150px;
    height: 35px;
    margin-top: 15px;
  }

  a.e-commerce-service3-liink {
    font-size: 16px;
    font-weight: 500;
  }

  .e-commerce-services-show {
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .about-us-hadding {
    font-size: 30px;
  }

  .E-commerece-about1 {
    margin-top: 2rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .E-commerece-about-2 {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  .E-commerece-about1::before {
    position: absolute;
    width: 0px;
    height: 0px;
    content: "";
    background: none;
    left: 0px;
    top: 0%;
    background-repeat: no-repeat;
    z-index: -1;
  }

  p.E-commerece-about-company-hadding {
    font-size: 20px;
  }

  p.E-commerece-about-company-para {
    font-size: 14px;
    font-weight: 400;
    font-family: 'Inter';
    color: #141631;
    padding-top: 10px;
  }

  p.platform-we-work-hadding {
    font-size: 20px;
  }

  .main-web-part-section {
    height: auto;
  }

  .web-devlopment-parts {
    margin-top: 40px;
  }

  p.web-devlopment-section-hadding {
    font-size: 20px;
    padding: 40px 0px 0px 0px;
  }

  .sion-web-languages {
    max-width: 100px !important;
  }

  .support-ecommerce-sec {
    margin: 2rem 0px;
  }

  .main-support-container {
    justify-content: center;
    gap: 30px;
  }

  .main-support-sf-container {
    height: 330px;
    max-width: 310px !important;
    margin-top: 10px;
    padding: 0px;
  }

  .row.sion-recent-workbg {
    height: auto;
    justify-content: center;
    padding: 0px 0px;
  }

  .sion-recent-work {
    margin: 2rem 0rem 1rem 0rem;
  }

  p.sion-recent-work-hadding {
    text-align: center;
    font-size: 20px;
  }

  p.sion-recent-work-para {
    text-align: center;
  }

  .col-lg-12.sion-package-blog-section1 {
    margin: 2rem 0px;
  }

  .sion-package-blog {
    background-image: none;
    background-repeat: no-repeat;
    background-size: 0px;
  }

  p.sion-package-hadding {
    font-size: 20px;
    color: #141631;
  }

  .sion-package-para {
    font-size: 14px;
    color: #141631;
  }

  .web-develop-languages {
    margin-bottom: 20px;
  }

  .row.sion-craft-sections {
    padding: 50px 0px;
  }

  p.sion-craft-top-hadding {
    font-size: 18px;
  }

  p.sion-craft-top-paragraph {
    font-size: 14px;
  }

  p.craft-bottom-cards2-name {
    font-size: 16px;
  }

}

@media only screen and (max-width: 767px) {
  .img-fix {
    display: none;
    align-items: center;
    justify-content: center;
  }

  .accordian-sec .mb-0>a {
    font-size: 15px;
  }

  .olcards {
    flex-direction: column;
  }

  .event-item {
    overflow: hidden;
    transition: all 0.3s ease 0s;
    margin-bottom: 15px;
  }

  p.main-web-content {
    font-size: 31px;
    margin-top: 5px;
  }

  .af-btm-sec-right {
    text-align: center;
    width: 100%;
  }

  p.af-btm-sec-para {
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Inter';
    padding-bottom: 15px;
    padding-top: 8px;
    text-align: justify;
  }

  .web-top5-section {
    display: flex;
    gap: 0px;
    flex-direction: column;
  }

  p.web-clg-how-work {
    font-size: 16px;
  }

  .web-best-section1 {
    border-radius: 17px;
  }

  .web-best-section2 {
    border-radius: 17px;
  }

  .web-best-section3 {
    border-radius: 17px;
  }

  .web-about-sec-top {
    left: 41%;
  }

  p.web-about-right-sec-para {
    line-height: 25px;
  }

  .web-sion-service-bottom {
    background-image: none;
    background-color: #009CA6;
    margin-top: 25px;
  }

  button.Seervice-ex-btn {
    margin-bottom: 30px;
  }

  .for-margin {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .af-btm-sec-images::before {
    position: absolute;
    content: "";
    background-image: none;
    bottom: 14px;
    z-index: -1;
    width: 0px;
    height: 0px;
    background-repeat: no-repeat;
    top: -4%;
  }

  ul.sion-top-footer-list {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    flex-direction: column;
    padding-left: 0px;
  }

  .sion-bottom-footer {
    padding: 10px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .sion-top-footer {
    padding: 50px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .social-icons {
    padding: 15px 0px;
    text-align: center;
  }

  .sion-top-footer-line {
    margin-bottom: 15px;
  }

  a.sion-bottom-footer-para {
    font-size: 13px;
    font-weight: 400;
    text-align: center;
  }

  a.sion-bottom-footer-privacy {
    font-size: 13px;
    font-weight: 400;
    text-align: center;
  }

  a.sion-bottom-footer-term {
    font-size: 13px;
    font-weight: 400;
    text-align: center;
  }

  .sion-bottom-footer {
    flex-direction: column;
  }

  .Hero-section {
    padding: 0px 0px;
  }

  .websion-best-section {
    flex-direction: column;
  }

  .web-best-section3 {
    width: 320px;
    height: 110px;
    gap: 10px;
  }

  .web-best-section2 {
    width: 320px;
    height: 110px;
    gap: 10px;
  }

  .web-best-section1 {
    width: 320px;
    height: 110px;
    gap: 10px;
  }

  .topnav {
    display: block;
  }

  a.left-nav-Number {
    font-size: 15px;
  }

  .sion-head-section {
    display: none;
  }

  .left-top-nav {
    gap: 20px;
    flex-direction: row;
  }

  .main-top-nav {
    gap: 10px;
    flex-direction: row;
  }

  .right-top-nav {
    display: none;
  }

  .main-top-nav {
    padding: 10px 0px;
    align-items: center;
    flex-wrap: nowrap;
    gap: 10px;
  }

  button.btn.button-get {
    width: 175px;
    height: 42px;
  }

  a.get-web-start {
    font-size: 20px;
    font-weight: 500;
  }

  .web-top5-main-section1 {
    width: 180px;
    height: 48px;
    margin-top: 10px;
  }

  .web-top5-section {
    padding: 0px;
  }

  h2.web-about-heading {
    margin-top: 50px;
  }

  p.web-cls-best2 {
    font-size: 21px;
    margin-top: 36px;
  }

  h2.web-about-heading {
    font-size: 21px;
  }

  h2.sion-service-hadding {
    font-size: 21px;
  }

  h2.web-customer-sec-hadding {
    font-size: 21px;
  }

  h3.service-tabs-hadding {
    font-size: 21px;
  }

  h2.sion-blog-sec-hadding {
    font-size: 21px;
  }

  p.after-main-web-content {
    font-size: 14px;
  }

  p.web-cls-best4 {
    font-size: 14px;
    padding-top: 5px;
  }

  p.web-about-paragraph {
    font-size: 14px;
  }

  p.web-about-right-sec-para {
    font-size: 14px;
  }

  .sion-service-discription {
    font-size: 14px;
  }

  p.af-btm-sec-para {
    font-size: 14px;
  }

  p.af-btm-sec-agter-hadd {
    font-size: 25px;
  }

  p.web-customer-sec-para {
    padding-top: 6px;
    font-size: 14px;
  }

  .web-sion-customer-sec {
    display: block;
    margin-top: 2rem;
    padding: 8px;

  }

  p.service-tabs-paragraph {
    font-size: 14px;
  }

  p.sion-blog-sec-pg {
    font-size: 14px;
    padding-top: 5px;
  }

  .sion-blog-sec-bottom-inner {
    margin-top: 30px;
  }

  .web-about-left-section {
    padding: 0px;
  }

  .sion-services-web-sections-row {
    margin-top: 1rem !important;
  }

  .tabs-links {
    font-weight: 400;
  }

  /* common */

  p.support-ecommerce-hadding {
    font-size: 20px;
    padding: 10px 0px;
  }

  p.support-ecommerce-paragraph {
    font-size: 14px;
  }

  p.support-hadding {
    font-size: 20px;
  }

  p.support-para {
    padding: 5px 20px;
  }

  .inter-show {
    width: 100%;
    height: auto;
  }

  /* About Section */
  .about-hero-section-dark {
    background-image: url(../../public/assets/images/aboutus.png);
    background-size: cover;
    background-repeat: no-repeat;
    padding: 58px 0px;
    object-fit: contain;
    background-position: center;
  }

  .about-us-hadding {
    font-size: 30px;
    font-weight: 600;
    color: white;
    font-family: 'Inter';
  }

  p.about-priority-paragraph {
    font-size: 16px;
    width: 320px;
    height: 160px;
  }

  .sdmi-about-image::before {
    background: none;
  }

  .sdmi-about-image {
    margin: 15px 0px;
  }

  .sdmi-about-image-sec {
    left: 0px;
    top: 0px;
  }

  .col-lg-5.col-sm-12.sdmi-about-image-sec {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .about-priority-para {
    margin: 25px 0px;
  }

  p.sion-vision-content-para {
    font-size: 14px;
    text-align: justify;
  }

  p.sion-about-section-para {
    font-size: 14px;
  }

  p.sdmi-about-us-para {
    font-size: 14px;
    padding-bottom: 15px;
    text-align: justify;
  }

  .after-vision-section {
    margin: 43px auto;
  }

  .after-vision-brand-para {
    width: 320px;
    height: 126px;
  }

  /* .after-bottom-lapi {
      bottom: 80px;
  } */

  .sdmi-about-image-sec::before {
    left: 0px;
    background: none;
  }

  /* Contact */
  .contact-hero-section {
    margin: 0px 0px;
  }

  .contact-out-section {
    padding: 25px 20px;
    margin: 30px 0px;
  }

  .form-group-space {
    margin-top: 15px;
  }

  .form-submit-btn {
    margin-top: 26px;
  }

}

@media only screen and (max-width: 425px) {
  p.main-web-content {
    font-size: 27px;
    margin-top: 5px;
  }

  .row.web-top5-section {
    display: flex;
    flex-direction: column;
    gap: 0px;
    margin: 0px;
    padding: 0pc;
  }

  .web-about-sec-top {
    left: 33%;
  }

  .sion-services-web {
    width: 300px;
  }

  /* .web-sion-customer-sec {
      width: 374px;
      height: 215px;
      margin-top: 3rem;
  } */

  .web-sion-customer-left {
    width: auto;
    height: 145px;
    margin-left: 13px;
    padding-left: 10px;
  }

  p.web-sion-customer-comment {
    right: 10px;
    margin-top: 15px;
    padding: 0px 15px;
    text-align: justify;
  }

  img.customer-real {
    width: 100px;
  }
}

@media only screen and (max-width: 375px) {
  p.main-web-content {
    font-size: 22px;
    margin-top: 10px;
  }

  .web-best-section1 {
    width: 320px;
  }

  .web-best-section2 {
    width: 320px;
  }

  .web-best-section3 {
    width: 320px;
  }

  .web-about-sec-top {
    left: 31%;
  }

  .web-sion-customer-left {
    width: 175px;
    height: 110px;
    margin-left: 8px;
  }
}

@media only screen and (max-width: 320x) {
  .web-about-sec-top {
    left: 29%;
  }
}

/* custome change blogs */
.blog-detailsall-head {
  font-size: 28px;
  font-weight: 700;
}

.blogspecial {
  font-size: 1rem;
}

.length-para-heading {
  font-weight: 700;
  font-size: 25px;
  padding: 7px 0px;
}

/* Digital web Blog */
:root {
  --white: #fff;
  --color-heading: #04000b;
  --color-paragraph: #787878;
  --color-primary: #0c5adb;
  --color-secondary: #3f1399;
  --bg-gray: #f3f7fd;
  --font-default: 'Yantramanav', sans-serif;
  --bg-gradient: linear-gradient(30deg, var(--color-secondary) 20%, var(--color-primary) 80%);
  --background-color: #ff735c42;
}

.digital_offer_section p {
  color: var(--color-paragraph);
  text-transform: none;
  font-weight: 400;
  font-family: var(--font-default);
  line-height: 1.7;
}

/* digital_offer_section-section-1-start------------------------- */
.digital_offer_section .thumb_img img {
  width: 100%;
  height: 100%;
  border-bottom-left-radius: 50px;
  border-top-right-radius: 50px;
}

.digital_offer_section {
  background-color: var(--background-color);
}

.digital_offer_section .b_discription .title {
  font-weight: 700;
  font-size: 39px;
  line-height: 1.1;
  color: #000;
}

.digital_offer_section .sub-title_line {
  color: #f77e53;
  font-size: 28px;
}

.digital_offer_section .sub-title {
  text-transform: uppercase;
  color: var(--color-primary);
  font-weight: 500;
  background: var(--bg-gradient);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  position: relative;
  z-index: 1;
}

.digital_offer_section .title_para {
  color: #565656;
}

.digital_offer_section .span_color {
  color: #064e73;
}

/* end */

/* row-2/3-start-------------------- */
.digital_offer_section .seo_package_ul img {
  max-width: 100px;
}

.digital_offer_section .seo_package_ul {
  background-color: #fff;
  padding: 25px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
}

.digital_offer_section .seo_package_ul ul li i {
  color: #f77e53;
}

.digital_offer_section .seo_package_ul h3 {
  color: #064e73;
  font-weight: 600;
  margin-top: 12px;
}

.digital_offer_section .seo_package_ul ul li {
  padding: 6px 10px;
  margin-bottom: 12px;
  box-shadow: 0 0 10px 0 rgb(51 51 51 / 17%) inset;
}

/* -end------------------ */
/* social media marketing Blog */
:root {
  --white: #fff;
  --color-heading: #04000b;
  --color-paragraph: #787878;
  --color-primary: #0c5adb;
  --color-secondary: #3f1399;
  --bg-gray: #f3f7fd;
  --font-default: 'Yantramanav', sans-serif;
  --bg-gradient: linear-gradient(30deg, var(--color-secondary) 20%, var(--color-primary) 80%);
  --background-color: #dbe1fe;

}

/* section-1-start */
.social-media-marketing_section {
  background-color: #dce1fe;
}

.social-media-marketing_section .thumb_img img {
  width: 100%;
  height: 100%;
  max-height: 370px;
  object-fit: cover;
}

.social-media-marketing_section {
  background-color: var(--background-color);
}

.social-media-marketing_section .b_discription .title {
  font-weight: 700;
  font-size: 37px;
  line-height: 1.1;
  color: #000;
}

.services-style-one-smm .info h2 {
  font-weight: 600;
  margin-bottom: 25px;
  color: #0293cc;
  text-shadow: 4px 6px 5px #ccc;
  font-size: 24px;
}

.sub-title_line {
  color: #f77e53;
}

.sub-title {
  text-transform: uppercase;
  color: var(--color-primary);
  font-weight: 500;
  background: var(--bg-gradient);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  position: relative;
  z-index: 1;
}

.social-media-marketing_section .title_para {
  color: #6a6a6a;
}

/* end */

/* section-2 */
.services-style-one-area-smm {
  position: relative;
  overflow: hidden;
}

.services-style-one-area-smm::before {
  position: absolute;
  content: "";
  width: 350px;
  height: 350px;
  background-color: #0293cc17;
  bottom: 80px;
  right: -30px;
  border-radius: 50%;
  z-index: 2;
}

.services-style-one-area-smm.bg-gray {
  background: var(--bg-gray);
}

.default-padding-top {
  padding-top: 60px;
}

.services-tab-navs-smm .nav-tabs {
  display: contents;
  background: var(--white);
  padding: 40px 20px;
  position: relative;
  padding-bottom: 50px;
  top: -80px;
  border: none;
  margin-bottom: -50px;
  border-radius: 8px;
  box-shadow: 0 5px 11px rgba(3, 27, 78, .08);
}

.services-tab-navs-smm .nav-tabs .nav-link:first-child {
  margin-top: 0;
}

.services-tab-navs-smm .nav-tabs .nav-link.active {
  border-color: aliceblue;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1);
  padding-left: 11px;
}

.services-tab-navs-smm .nav-tabs .nav-link {
  margin: 0;
  padding: 15px 0;
  width: 100%;
  text-align: left;
  border: none;
  border-bottom: 1px solid #dcd6d6;
  color: var(--color-heading);
  font-size: 20px;
  font-weight: 600;
  transition: all 0.35s ease-in-out;
  position: relative;
  display: flex;
  align-items: center;
  position: relative;
}

.services-tab-navs-smm .nav-tabs .nav-link.active::before {
  opacity: 1;
  visibility: visible;
}

.services-tab-navs-smm .nav-tabs .nav-link::before {
  position: absolute;
  content: "";
  top: 20px;
  right: -39px;
  height: 40px;
  width: 40px;
  border-left: solid var(--white);
  border-top: solid transparent;
  border-bottom: solid transparent;
  border-width: 20px;
  -webkit-filter: drop-shadow(3px 0 0 rgba(3, 27, 78, .03));
  filter: drop-shadow(3px 0 0 rgba(3, 27, 78, .03));
  transition: all .4s ease;
  visibility: hidden;
  opacity: 0;
}

.services-tab-navs-smm .nav-tabs .nav-link.active i {
  color: var(--color-primary);
}

.services-tab-navs-smm .nav-tabs .nav-link i {
  display: block;
  font-size: 55px;
  margin-right: 20px;
  position: relative;
  top: 5px;
}

.services-tab-navs-smm .nav-tabs .nav-link b {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  color: var(--color-paragraph);
  letter-spacing: 0.6px;
  display: block;
  margin-bottom: 0;
}

.services-style-one-smm .thumb {
  position: relative;
  z-index: 1;
}

.services-style-one-smm .thumb::after {
  position: absolute;
  right: -100px;
  top: -70px;
  content: "";
  height: 300px;
  width: 300px;
  background: linear-gradient(20deg, transparent, rgba(255, 255, 255, 1));
  z-index: -1;
  border-radius: 50%;
}

.services-style-one-smm .info {
  position: relative;
  z-index: 1;
}

.services-style-one-smm .info h2 {
  font-weight: 600;
  margin-bottom: 25px;
}

.services-style-one-smm ul.list-standard {
  margin: 0;
  padding: 0;
  list-style: none;
}

.services-style-one-smm .list-standard li {
  position: relative;
  font-size: 15px;
  padding-left: 30px;
  line-height: 2;
  font-weight: 500;
  color: var(--color-paragraph);
}

.services-style-one-smm .thumb img {
  width: 100%;
  height: 100%;
}

.services-style-one-smm .list-standard li i {
  margin-right: 10px;
  color: #0293cc;
}

.services-tab-navs-smm .nav-link .icon img {
  max-width: 60px;
  height: auto;
  margin-right: 15px;
}

.nav-tabs .nav-link.active {
  color: #0293cc !important;
}

.services-tab-navs-smm {
  border: 1px solid gainsboro;
  padding: 23px;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
}

/* end */
/* ServicePge */
.service_crd_img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  object-fit: cover;
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
  box-shadow: 0 0 5px 1px #d3d3d3;

  &:hover {
    width: 95px;
    border: 2px dashed gray;
  }
}

/* handelerror pge */
.errorhandel {
  height: 75vh;
  background-color: lightgray;
}

.loginpgimg {
  width: 185px;
}

:root {
  --white: #fff;
  --color-heading: #04000b;
  --color-paragraph: #787878;
  --color-primary: #0c5adb;
  --color-secondary: #3f1399;
  --bg-gray: #f3f7fd;
  --font-default: 'Yantramanav', sans-serif;
  --bg-gradient: linear-gradient(30deg, var(--color-secondary) 20%, var(--color-primary) 80%);
  --background-color: #fff;

}

/* section-1-start */
.advertising_section {
  background-color: #dce1fe;
}

.advertising_section .thumb_img img {
  width: 100%;
  height: 100%;
  max-height: 370px;
  object-fit: cover;
}

.advertising_section {
  background-color: var(--background-color);
}

.advertising_section .b_discription .title {
  font-weight: 700;
  font-size: 35px;
  line-height: 1.1;
  color: #000;
  text-shadow: 4px 6px 5px #ccc;
}

.services-ad-style .info .st-details {
  font-weight: 600;
  margin-bottom: 25px;
  color: #0293cc;
  text-shadow: 4px 6px 5px #ccc;
  font-size: 24px;
}

.advertising_section .title_para {
  color: #6a6a6a;
}

/* end */

/* section-2 */
.services_advertising {
  position: relative;
  overflow: hidden;
}

.services_advertising::before {
  position: absolute;
  content: "";
  width: 350px;
  height: 350px;
  background-color: #0293cc17;
  bottom: 80px;
  right: -30px;
  border-radius: 50%;
  z-index: 2;
}

.services_advertising.bg-gray {
  background: var(--bg-gray);
}

.services-tab-ad .nav-tabs {
  display: contents;
  background: var(--white);
  padding: 40px 20px;
  position: relative;
  padding-bottom: 50px;
  top: -80px;
  border: none;
  margin-bottom: -50px;
  border-radius: 8px;
  box-shadow: 0 5px 11px rgba(3, 27, 78, .08);
}

.services-tab-ad .nav-tabs .nav-link:first-child {
  margin-top: 0;
}

.services-tab-ad .nav-tabs .nav-link.active {
  border-color: aliceblue;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1);
  padding-left: 11px;
}

.services-tab-ad .nav-tabs .nav-link {
  margin: 0;
  padding: 15px 0;
  width: 100%;
  text-align: left;
  border: none;
  border-bottom: 1px solid #dcd6d6;
  color: var(--color-heading);
  font-size: 20px;
  font-weight: 600;
  transition: all 0.35s ease-in-out;
  position: relative;
  display: flex;
  align-items: center;
  position: relative;
}

.services-tab-ad .nav-tabs .nav-link.active::before {
  opacity: 1;
  visibility: visible;
}

.services-tab-ad .nav-tabs .nav-link::before {
  position: absolute;
  content: "";
  top: 20px;
  right: -39px;
  height: 40px;
  width: 40px;
  border-left: solid var(--white);
  border-top: solid transparent;
  border-bottom: solid transparent;
  border-width: 20px;
  -webkit-filter: drop-shadow(3px 0 0 rgba(3, 27, 78, .03));
  filter: drop-shadow(3px 0 0 rgba(3, 27, 78, .03));
  transition: all .4s ease;
  visibility: hidden;
  opacity: 0;
}

.services-tab-ad .nav-tabs .nav-link.active i {
  color: var(--color-primary);
}

.services-tab-ad .nav-tabs .nav-link i {
  display: block;
  font-size: 55px;
  margin-right: 20px;
  position: relative;
  top: 5px;
}

.services-tab-ad .nav-tabs .nav-link b {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  color: var(--color-paragraph);
  letter-spacing: 0.6px;
  display: block;
  margin-bottom: 0;
}

.services-ad-style .thumb {
  position: relative;
  z-index: 1;
}

.services-ad-style .thumb::after {
  position: absolute;
  right: -100px;
  top: -70px;
  content: "";
  height: 300px;
  width: 300px;
  background: linear-gradient(20deg, transparent, rgba(255, 255, 255, 1));
  z-index: -1;
  border-radius: 50%;
}

.services-ad-style .info {
  position: relative;
  z-index: 1;
}

.services-ad-style .info .st-details {
  font-weight: 600;
  margin-bottom: 25px;
}

.services-ad-style ul.list-standard {
  margin: 0;
  padding: 0;
  list-style: none;
}

.services-ad-style .list-standard li {
  position: relative;
  font-size: 15px;
  padding-left: 30px;
  line-height: 2;
  font-weight: 500;
  color: var(--color-paragraph);
}

.services-ad-style .thumb img {
  width: 100%;
  height: 100%;
}

.services-ad-style .list-standard li i {
  margin-right: 10px;
  color: #0293cc;
}

.services-tab-ad .nav-link .icon img {
  max-width: 60px;
  height: auto;
  margin-right: 15px;
}

.services-tab-ad .nav-tabs .nav-link.active {
  color: #0293cc !important;
}
.services-tab-ad {
  border: 1px solid gainsboro;
  padding: 23px;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
}
/* end */

/* new landing page */
.landingPagefrm {
  box-shadow: 0px 5px 5px 0px #bdb4b4;
  padding: 0px 12px 4px 12px;
  border-radius: 5px;
}

.landingpage {
  background-color: white;
  padding: 6px;
  color: #6b6464;
  border-radius: 2px 0px 0px 3px;
  border: 1px #8080808c solid;
}

/* main .contentlpage a {
  text-decoration: none;
  color: #838383;
  font-size: 0.9rem;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
} */

main .contentlpage a:hover {
  color: #eb8a88;
}

main .contentlpage span {
  text-transform: uppercase;
  font-size: 0.9rem;
  letter-spacing: 0.125rem;
  color: #F7861F;
  font-weight: 500;
}

main .contentlpage h1 {
  text-transform: capitalize;
  font-size: clamp(0.5rem, 4vw, 3rem);
  font-weight: 700;
  margin: 0.2em 0 0.6rem 0;
}

main .contentlpage p {
  max-width: 350px;
}

main .contentlpage svg {
  width: 26px;
  margin-right: 10px;
}

/* Service Quotation Page */

img.qutionicn.img-fluid {
  width: 70px;
  height: 70px;
  position: fixed;
  bottom: 20px;
  right: 30px;
  background: aliceblue;
  border-radius: 50%;
  z-index: 9;
  cursor: pointer;
  animation: spin 30s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }

}

button.closebtn {
  background-color: #615b5bbd;
  box-shadow: 0px 0px 5px 0px;
  font-weight: 700;
}

.serviceQform {
  padding: 5px 10px;
  box-shadow: 0px 0px 1px 0px;
}

/* Service Quotation Page  End*/
/* SingleServicePlan Start */

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none;
}

a,
a:active,
a:focus {
  color: #333;
  text-decoration: none;
  transition-timing-function: ease-in-out;
  -ms-transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-duration: .2s;
  -ms-transition-duration: .2s;
  -moz-transition-duration: .2s;
  -webkit-transition-duration: .2s;
  -o-transition-duration: .2s;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

/*--blog----*/

.sec-title {
  position: relative;
  margin-bottom: 70px;
}

.sec-title .title {
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 1em;
  color: #ff8a01;
  font-weight: 500;
  background: rgb(247, 0, 104);
  background: -moz-linear-gradient(to left, rgba(247, 0, 104, 1) 0%, rgba(68, 16, 102, 1) 25%, rgba(247, 0, 104, 1) 75%, rgba(68, 16, 102, 1) 100%);
  background: -webkit-linear-gradient(to left, rgba(247, 0, 104, 1) 0%, rgba(68, 16, 102, 1) 25%, rgba(247, 0, 104, 1) 75%, rgba(68, 16, 102, 1) 100%);
  background: linear-gradient(to left, rgba(247, 0, 104) 0%, rgba(68, 16, 102, 1) 25%, rgba(247, 0, 104, 1) 75%, rgba(68, 16, 102, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#F70068', endColorstr='#441066', GradientType=1);
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  letter-spacing: 5px;
  margin-bottom: 15px;
}

.sec-title h2 {
  position: relative;
  display: inline-block;
  font-size: 30px;
  line-height: 0.2em;
  color: #f4881f;
  font-weight: 500;
}

.sec-title .text {
  position: relative;
  font-size: 16px;
  line-height: 28px;
  color: #888888;
  margin-top: 30px;
}

.sec-title::before {
  position: absolute;
  content: "";
  width: 150px;
  height: 5px;
  left: 42%;
  bottom: 0;
  right: 50%;
  margin-bottom: -5px;
  background: var(--primary);
  border-radius: 2px;
}

.sec-title::after {
  position: absolute;
  content: "";
  width: 6px;
  height: 5px;
  bottom: 0px;
  left: 44%;
  margin-bottom: -5px;
  background: #ffffff;
  animation: section-title-run 3s infinite linear;
}

@keyframes section-title-run {
  0% {
    margin-right: 30px;
  }

  100% {
    margin-left: 100px;
  }
}

.sec-title.light h2,
.sec-title.light .title {
  color: #ffffff;
  -webkit-text-fill-color: inherit;
}

.pricing-section {
  position: relative;
  padding: 25px 0 80px;
  overflow: hidden;
}

.pricing-section .outer-box {
  max-width: 1100px;
  margin: 0 auto;
}

.pricing-section .row {
  margin: 0 -30px;
}

.pricing-block {
  position: relative;
  padding: 0 30px;
  margin-bottom: 40px;
}

.pricing-block .inner-box {
  position: relative;
  background-color: #ffffff;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.08);
  padding: 0 0 30px;
  max-width: 370px;
  margin: 0 auto;
  border-bottom: 20px solid #40cbb4;
}

.pricing-block .icon-box {
  position: relative;
  padding: 50px 30px 0;
  background-color: #40cbb4;
  text-align: center;
}

.pricing-block .icon-box:before {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 75px;
  width: 100%;
  border-radius: 50% 50% 0 0;
  background-color: #ffffff;
  content: "";
}

.pricing-block .icon-box .icon-outer {
  position: relative;
  height: 150px;
  width: 150px;
  background-color: #ffffff;
  border-radius: 50%;
  margin: 0 auto;
  padding: 10px;
}

.pricing-block .icon-box i {
  position: relative;
  display: block;
  height: 130px;
  width: 130px;
  line-height: 120px;
  border: 5px solid #40cbb4;
  border-radius: 50%;
  font-size: 50px;
  color: #40cbb4;
  -webkit-transition: all 600ms ease;
  -ms-transition: all 600ms ease;
  -o-transition: all 600ms ease;
  -moz-transition: all 600ms ease;
  transition: all 600ms ease;
}

.pricing-block .inner-box:hover .icon-box i {
  transform: rotate(360deg);
}

.pricing-block .price-box {
  position: relative;
  text-align: center;
  padding: 10px 20px;
}

.pricing-block .title {
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 1.2em;
  color: #222222;
  font-weight: 600;
}
.pricing-block .title_Sr {
  font-size: 20px;
  font-weight: 700;
  color: #42afd7;
  padding: 2px;
}
.pricing-block .price {
  display: block;
  font-size: 30px;
  color: #222222;
  font-weight: 700;
  color: #40cbb4;
}

.pricing-block .features {
  position: relative;
  max-width: 200px;
  margin: 0 auto 20px;
}

.pricing-block .features li {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 30px;
  color: #848484;
  font-weight: 500;
  padding: 5px 0;
  padding-left: 30px;
  border-bottom: 1px dashed #dddddd;
  
}
.pln_icon svg{
  font-size: 20px;
}
.pricing-block .features li:before {
  content: "\f058";
  position: absolute;
  left: 0;
  top: 50%;
  font-size: 16px;
  color: #2bd40f;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Free";
  margin-top: -8px;
}

.pricing-block .features li a {
  color: #848484;
}

.pricing-block .features li:last-child {
  border-bottom: 0;
}

.pricing-block .btn-box {
  position: relative;
  text-align: center;
}

.pricing-block .btn-box a {
  position: relative;
  display: inline-block;
  font-size: 14px;
  line-height: 25px;
  color: #ffffff;
  font-weight: 500;
  padding: 8px 30px;
  background-color: #40cbb4;
  border-radius: 10px;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  -webkit-transition: all 400ms ease;
  -moz-transition: all 400ms ease;
  -ms-transition: all 400ms ease;
  -o-transition: all 400ms ease;
  transition: all 300ms ease;
}

.pricing-block .btn-box a:hover {
  color: #ffffff;
}

.pricing-block .inner-box:hover .btn-box a {
  color: #40cbb4;
  background: none;
  border-radius: 0px;
  border-color: #40cbb4;
}

.pricing-block:nth-child(2) .icon-box i,
.pricing-block:nth-child(2) .inner-box {
  border-color: #1d95d2;
}

.pricing-block:nth-child(2) .btn-box a,
.pricing-block:nth-child(2) .icon-box {
  background-color: #1d95d2;
}

.pricing-block:nth-child(2) .inner-box:hover .btn-box a {
  color: #1d95d2;
  background: none;
  border-radius: 0px;
  border-color: #1d95d2;
}

.pricing-block:nth-child(2) .icon-box i,
.pricing-block:nth-child(2) .price {
  color: #1d95d2;
}

.pricing-block:nth-child(3) .icon-box i,
.pricing-block:nth-child(3) .inner-box {
  border-color: #F4881F;
}

.pricing-block:nth-child(3) .btn-box a,
.pricing-block:nth-child(3) .icon-box {
  background-color: #F4881F;
}

.pricing-block:nth-child(3) .icon-box i,
.pricing-block:nth-child(3) .price {
  color: #F4881F;
}

.pricing-block:nth-child(3) .inner-box:hover .btn-box a {
  color: #ffc20b;
  background: none;
  border-radius: 0px;
  border-color: #ffc20b;
}

/* SingleServicePlan */
/* Loader */

.loader {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader:before,
.loader:after {
  content: '';
  width: 15px;
  height: 15px;
  display: inline-block;
  position: relative;
  margin: 0 5px;
  border-radius: 50%;
  color:#0997D0;
  background: currentColor;
  box-shadow: 50px 0, -50px 0;
  animation: left 1s infinite ease-in-out;
}

.loader:after {
  color: #F4881F;
  animation: right 1.1s infinite ease-in-out;
}

@keyframes right {

  0%,
  100% {
    transform: translateY(-10px)
  }

  50% {
    transform: translateY(10px)
  }
}

@keyframes left {

  0%,
  100% {
    transform: translateY(10px)
  }

  50% {
    transform: translateY(-10px)
  }
}
.Init {
  color: #E09BA9;
}
.Itin {
  color: #d77ccf;
}
.iconlpge{
  clip-path: polygon(50% 0%, 83% 12%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0% 43%, 17% 12%);
}
.flandingpge {
  box-shadow: 0px 1px 5px 1px #c0b6b69c;
  border-radius: 3px;
}
.landing_page_card{
  box-shadow: 2px 2px 2px 2px #0000002e;
    padding: 19px;   
}
ul.landing_page{
  gap:20px;
  /* flex-direction: column; */
}
@media (max-width:767px){
  ul.landing_page{
    gap:20px;
    flex-direction: column;
  }
}
.lapge_text{
  font-size:15px;
}